import React, {useEffect, useState} from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import {ReactQueryDevtools} from 'react-query/devtools';
import {BrowserRouter as Router} from 'react-router-dom';
import theme from './theme/application';
import IyoAuth from './auth/Auth';
import HubListener from './components/auth/HubListener.jsx';
import NavigationLayout from './components/navigation/NavigationLayout';
import NavigationTopBar from './components/navigation/topbar/NavigationTopBar';
import NavigationSideBar from './components/navigation/sidebar/NavigationSideBar';
import NavigationBottomBar from './components/navigation/bottombar/NavigationBottomBar';
import Routes from './components/routes/Routes.jsx';

const NavigationTopBarNgest = ({}) => {
  return (<NavigationTopBar/>);
}

const App = ({}) => {
  const [sessionChange, setSessionChange] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    IyoAuth.isLoggedIn().then(bool => {
      setLoggedIn(bool);
    });
    setSessionChange(false);
  }, [sessionChange, setSessionChange]);

  // Intercept warning logs masquerading as error logs
  useEffect(() => {
    const err = console.error;
    console.error = (...args) => {
      if (typeof args[0] === 'string') {
        if (args[0].includes('Warning')) {
          console.warn(args[0]);
          return;
        }
      }
      err(...args);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Router>
          <HubListener setSessionUpdate={setSessionChange}/>
          <NavigationLayout isLoggedIn={isLoggedIn}
                            topbar={NavigationTopBarNgest}
                            sidebar={NavigationSideBar}
                            bottombar={NavigationBottomBar} >
            <Routes/>
          </NavigationLayout>
        </Router>
        {
          process.env.DOMAIN === undefined || process.env.DOMAIN === '' ?
            <ReactQueryDevtools initialIsOpen={false} /> :
            null
        }
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;