import axios from 'axios';
import {fetchConfig} from '../util/util';

const HOST_URL = fetchConfig('hostUrl')

const getOneById = async (providerTypeId) => {
  return (await axios.get(`${HOST_URL}/consumer-type/${providerTypeId}`)).data;
};

const getAll = async () => {
  return (await axios.get(`${HOST_URL}/consumer-type`)).data;
};

export default {getOneById, getAll};