import React, {useState} from 'react';
import {useQueryClient} from 'react-query';
import {Box, LinearProgress, Paper} from "@mui/material";
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import Canvas from "../../navigation/canvas/Canvas";
import DataStreamEditDialog from './data-stream-edit-dialog/DataStreamEditDialog';
import ErrorPopup from "../../common/ErrorPopup";
import ScrollableDialog from "../../common/ScrollableDialog";
import TableToolbar from '../../common/TableToolbar';
import {columns} from "./columns";
import Hooks from './hooks';
import Helpers from './helpers';
import Handlers from './handlers'

const DataStreams = ({}) => {
  const [consumerId, setConsumerId] = useState(null);
  const [cron, setCron] = useState('');
  const [dataStreamFields, setDataStreamFields] = useState(['id', 'name', 'description',
    'cron', 'last_retrieval_date', 'provider_id', 'owner_id', 'provider_properties', 'created_by',
    'updated_by']);
  const [dataStreamId, setDataStreamId] = useState(null);
  const [description, setDescription] = useState('');
  const [lastRetrievalDate, setLastRetrievalDate] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [editMode, setEditMode] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [errDisplayText, setErrDisplayText] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [name, setName] = useState('');
  const [originalConsumers, setOriginalConsumers] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [providerId, setProviderId] = useState(null);
  const [rowEditing, setRowEditing] = useState([]);

  const state = {consumerId, dataStreamFields, dataStreamId, description, cron, lastRetrievalDate,
    dialogContent, dialogOpen, dialogTitle, editMode, editOpen, errDisplayText, errorOpen, name,
    originalConsumers, ownerId, providerId, rowEditing};

  const setState = {setConsumerId, setDataStreamFields, setDataStreamId, setDescription, setCron,
    setLastRetrievalDate, setDialogContent, setDialogOpen, setDialogTitle, setEditMode, setEditOpen,
    setErrDisplayText, setErrorOpen, setName, setOriginalConsumers, setOwnerId, setProviderId,
    setRowEditing};

  const queryClient = useQueryClient();

  const [cols, setCols] = useState(Helpers.initializeColumns(columns, queryClient, setState));

  const getDataStreams = Hooks.useDataStreamQuery('GET', queryClient, state, setState);
  
  Hooks.useOwnerDefaultDataStreamQuery('GET', state, setState);

  return (
    <Box>
      <Canvas appName='nGEST' title='Data Streams'>
        <TableToolbar editHandler={() => Handlers.handleCreate(setState)}
                      onRefresh={() => Handlers.refreshTable(queryClient)}/>
        <Paper sx={{height: '77vh', '& .disabled': {color: "#b2b29b"}}}>
          <DataGridPro
            columns={cols}
            rows={getDataStreams.data || []}
            getRowClassName={(params) => params.row.disabled ? 'disabled' : ''}
            slots={{loadingOverlay: LinearProgress, toolbar: GridToolbar}}
            loading={getDataStreams.isLoading}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  description: false,
                  owner_id: false,
                  owner_description: false,
                  provider_id: false,
                  provider_description: false,
                  created_by: false,
                  created_datetime: false,
                  updated_by: false,
                  updated_datetime: false
                }
              }
            }}
            density='compact'
          />
        </Paper>
      </Canvas>
      <DataStreamEditDialog
        state={state}
        setState={setState}
      />
      <ScrollableDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        content={dialogContent}
        title={dialogTitle}
        scroll={'paper'}
      />
      <ErrorPopup
        open={errorOpen}
        setOpen={setErrorOpen}
        displayText={errDisplayText}
      />
    </Box>
  );
};

export default DataStreams;