import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { unstable_ownerDocument as ownerDocument, unstable_useEventCallback as useEventCallback } from '@mui/utils';
import { gridClasses, useGridApiEventHandler, useGridApiOptionHandler, useGridApiMethod, useGridNativeEventListener, useGridLogger, useGridSelector, gridVirtualizationColumnEnabledSelector } from '@mui/x-data-grid';
import { clamp, findParentElementFromClassName, gridColumnsStateSelector, useOnMount, useTimeout, createControllablePromise } from '@mui/x-data-grid/internals';
import { useTheme } from '@mui/material/styles';
import { findGridCellElementsFromCol, getFieldFromHeaderElem, findHeaderElementFromField, findGroupHeaderElementsFromField, findGridHeader, findGridCells } from '../../../utils/domUtils';
import { DEFAULT_GRID_AUTOSIZE_OPTIONS } from './gridColumnResizeApi';
// TODO: remove support for Safari < 13.
// https://caniuse.com/#search=touch-action
//
// Safari, on iOS, supports touch action since v13.
// Over 80% of the iOS phones are compatible
// in August 2020.
// Utilizing the CSS.supports method to check if touch-action is supported.
// Since CSS.supports is supported on all but Edge@12 and IE and touch-action
// is supported on both Edge@12 and IE if CSS.supports is not available that means that
// touch-action will be supported
let cachedSupportsTouchActionNone = false;
function doesSupportTouchActionNone() {
  if (cachedSupportsTouchActionNone === undefined) {
    if (typeof CSS !== 'undefined' && typeof CSS.supports === 'function') {
      cachedSupportsTouchActionNone = CSS.supports('touch-action', 'none');
    } else {
      cachedSupportsTouchActionNone = true;
    }
  }
  return cachedSupportsTouchActionNone;
}
function trackFinger(event, currentTouchId) {
  if (currentTouchId !== undefined && event.changedTouches) {
    for (let i = 0; i < event.changedTouches.length; i += 1) {
      const touch = event.changedTouches[i];
      if (touch.identifier === currentTouchId) {
        return {
          x: touch.clientX,
          y: touch.clientY
        };
      }
    }
    return false;
  }
  return {
    x: event.clientX,
    y: event.clientY
  };
}
function computeNewWidth(initialOffsetToSeparator, clickX, columnBounds, resizeDirection) {
  let newWidth = initialOffsetToSeparator;
  if (resizeDirection === 'Right') {
    newWidth += clickX - columnBounds.left;
  } else {
    newWidth += columnBounds.right - clickX;
  }
  return newWidth;
}
function computeOffsetToSeparator(clickX, columnBounds, resizeDirection) {
  if (resizeDirection === 'Left') {
    return clickX - columnBounds.left;
  }
  return columnBounds.right - clickX;
}
function flipResizeDirection(side) {
  if (side === 'Right') {
    return 'Left';
  }
  return 'Right';
}
function getResizeDirection(element, direction) {
  const side = element.classList.contains(gridClasses['columnSeparator--sideRight']) ? 'Right' : 'Left';
  if (direction === 'rtl') {
    // Resizing logic should be mirrored in the RTL case
    return flipResizeDirection(side);
  }
  return side;
}
function preventClick(event) {
  event.preventDefault();
  event.stopImmediatePropagation();
}

/**
 * Checker that returns a promise that resolves when the column virtualization
 * is disabled.
 */
function useColumnVirtualizationDisabled(apiRef) {
  const promise = React.useRef();
  const selector = () => gridVirtualizationColumnEnabledSelector(apiRef);
  const value = useGridSelector(apiRef, selector);
  React.useEffect(() => {
    if (promise.current && value === false) {
      promise.current.resolve();
      promise.current = undefined;
    }
  });
  const asyncCheck = () => {
    if (!promise.current) {
      if (selector() === false) {
        return Promise.resolve();
      }
      promise.current = createControllablePromise();
    }
    return promise.current;
  };
  return asyncCheck;
}

/**
 * Basic statistical outlier detection, checks if the value is `F * IQR` away from
 * the Q1 and Q3 boundaries. IQR: interquartile range.
 */
function excludeOutliers(inputValues, factor) {
  if (inputValues.length < 4) {
    return inputValues;
  }
  const values = inputValues.slice();
  values.sort((a, b) => a - b);
  const q1 = values[Math.floor(values.length * 0.25)];
  const q3 = values[Math.floor(values.length * 0.75) - 1];
  const iqr = q3 - q1;

  // We make a small adjustment if `iqr < 5` for the cases where the IQR is
  // very small (e.g. zero) due to very close by values in the input data.
  // Otherwise, with an IQR of `0`, anything outside that would be considered
  // an outlier, but it makes more sense visually to allow for this 5px variance
  // rather than showing a cropped cell.
  const deviation = iqr < 5 ? 5 : iqr * factor;
  return values.filter(v => v > q1 - deviation && v < q3 + deviation);
}
function extractColumnWidths(apiRef, options, columns) {
  const widthByField = {};
  const root = apiRef.current.rootElementRef.current;
  root.classList.add(gridClasses.autosizing);
  columns.forEach(column => {
    const cells = findGridCells(apiRef.current, column.field);
    const widths = cells.map(cell => {
      var _cell$firstElementChi, _cell$firstElementChi2;
      const style = window.getComputedStyle(cell, null);
      const paddingWidth = parseInt(style.paddingLeft, 10) + parseInt(style.paddingRight, 10);
      const contentWidth = (_cell$firstElementChi = (_cell$firstElementChi2 = cell.firstElementChild) == null ? void 0 : _cell$firstElementChi2.getBoundingClientRect().width) != null ? _cell$firstElementChi : 0;
      return paddingWidth + contentWidth;
    });
    const filteredWidths = options.includeOutliers ? widths : excludeOutliers(widths, options.outliersFactor);
    if (options.includeHeaders) {
      const header = findGridHeader(apiRef.current, column.field);
      if (header) {
        const title = header.querySelector(`.${gridClasses.columnHeaderTitle}`);
        const content = header.querySelector(`.${gridClasses.columnHeaderTitleContainerContent}`);
        const element = title != null ? title : content;
        const style = window.getComputedStyle(header, null);
        const paddingWidth = parseInt(style.paddingLeft, 10) + parseInt(style.paddingRight, 10);
        const contentWidth = element.scrollWidth + 1;
        const width = paddingWidth + contentWidth;
        filteredWidths.push(width);
      }
    }
    const hasColumnMin = column.minWidth !== -Infinity && column.minWidth !== undefined;
    const hasColumnMax = column.maxWidth !== Infinity && column.maxWidth !== undefined;
    const min = hasColumnMin ? column.minWidth : 0;
    const max = hasColumnMax ? column.maxWidth : Infinity;
    const maxContent = filteredWidths.length === 0 ? 0 : Math.max(...filteredWidths);
    widthByField[column.field] = clamp(maxContent, min, max);
  });
  root.classList.remove(gridClasses.autosizing);
  return widthByField;
}
export const columnResizeStateInitializer = state => _extends({}, state, {
  columnResize: {
    resizingColumnField: ''
  }
});
/**
 * @requires useGridColumns (method, event)
 * TODO: improve experience for last column
 */
export const useGridColumnResize = (apiRef, props) => {
  const logger = useGridLogger(apiRef, 'useGridColumnResize');
  const colDefRef = React.useRef();
  const colElementRef = React.useRef();
  const headerFilterElementRef = React.useRef();
  const colGroupingElementRef = React.useRef();
  const colCellElementsRef = React.useRef();
  const theme = useTheme();

  // To improve accessibility, the separator has padding on both sides.
  // Clicking inside the padding area should be treated as a click in the separator.
  // This ref stores the offset between the click and the separator.
  const initialOffsetToSeparator = React.useRef();
  const resizeDirection = React.useRef();
  const stopResizeEventTimeout = useTimeout();
  const touchId = React.useRef();
  const updateWidth = newWidth => {
    logger.debug(`Updating width to ${newWidth} for col ${colDefRef.current.field}`);
    const prevWidth = colElementRef.current.offsetWidth;
    const widthDiff = newWidth - prevWidth;
    colDefRef.current.computedWidth = newWidth;
    colDefRef.current.width = newWidth;
    colDefRef.current.flex = 0;
    colElementRef.current.style.width = `${newWidth}px`;
    colElementRef.current.style.minWidth = `${newWidth}px`;
    colElementRef.current.style.maxWidth = `${newWidth}px`;
    const headerFilterElement = headerFilterElementRef.current;
    if (headerFilterElement) {
      headerFilterElement.style.width = `${newWidth}px`;
      headerFilterElement.style.minWidth = `${newWidth}px`;
      headerFilterElement.style.maxWidth = `${newWidth}px`;
    }
    [...colCellElementsRef.current, ...colGroupingElementRef.current].forEach(element => {
      const div = element;
      let finalWidth;
      if (div.getAttribute('aria-colspan') === '1') {
        finalWidth = `${newWidth}px`;
      } else {
        // Cell with colspan > 1 cannot be just updated width new width.
        // Instead, we add width diff to the current width.
        finalWidth = `${div.offsetWidth + widthDiff}px`;
      }
      div.style.width = finalWidth;
      div.style.minWidth = finalWidth;
      div.style.maxWidth = finalWidth;
    });
  };
  const finishResize = nativeEvent => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    stopListening();
    if (colDefRef.current) {
      apiRef.current.setColumnWidth(colDefRef.current.field, colDefRef.current.width);
      logger.debug(`Updating col ${colDefRef.current.field} with new width: ${colDefRef.current.width}`);
    }
    stopResizeEventTimeout.start(0, () => {
      apiRef.current.publishEvent('columnResizeStop', null, nativeEvent);
    });
  };
  const handleResizeMouseUp = useEventCallback(finishResize);
  const handleResizeMouseMove = useEventCallback(nativeEvent => {
    // Cancel move in case some other element consumed a mouseup event and it was not fired.
    if (nativeEvent.buttons === 0) {
      handleResizeMouseUp(nativeEvent);
      return;
    }
    let newWidth = computeNewWidth(initialOffsetToSeparator.current, nativeEvent.clientX, colElementRef.current.getBoundingClientRect(), resizeDirection.current);
    newWidth = clamp(newWidth, colDefRef.current.minWidth, colDefRef.current.maxWidth);
    updateWidth(newWidth);
    const params = {
      element: colElementRef.current,
      colDef: colDefRef.current,
      width: newWidth
    };
    apiRef.current.publishEvent('columnResize', params, nativeEvent);
  });
  const handleTouchEnd = useEventCallback(nativeEvent => {
    const finger = trackFinger(nativeEvent, touchId.current);
    if (!finger) {
      return;
    }
    finishResize(nativeEvent);
  });
  const handleTouchMove = useEventCallback(nativeEvent => {
    const finger = trackFinger(nativeEvent, touchId.current);
    if (!finger) {
      return;
    }

    // Cancel move in case some other element consumed a touchmove event and it was not fired.
    if (nativeEvent.type === 'mousemove' && nativeEvent.buttons === 0) {
      handleTouchEnd(nativeEvent);
      return;
    }
    let newWidth = computeNewWidth(initialOffsetToSeparator.current, finger.x, colElementRef.current.getBoundingClientRect(), resizeDirection.current);
    newWidth = clamp(newWidth, colDefRef.current.minWidth, colDefRef.current.maxWidth);
    updateWidth(newWidth);
    const params = {
      element: colElementRef.current,
      colDef: colDefRef.current,
      width: newWidth
    };
    apiRef.current.publishEvent('columnResize', params, nativeEvent);
  });
  const handleTouchStart = useEventCallback(event => {
    var _apiRef$current$colum, _apiRef$current$colum2;
    const cellSeparator = findParentElementFromClassName(event.target, gridClasses['columnSeparator--resizable']);
    // Let the event bubble if the target is not a col separator
    if (!cellSeparator) {
      return;
    }
    // If touch-action: none; is not supported we need to prevent the scroll manually.
    if (!doesSupportTouchActionNone()) {
      event.preventDefault();
    }
    const touch = event.changedTouches[0];
    if (touch != null) {
      // A number that uniquely identifies the current finger in the touch session.
      touchId.current = touch.identifier;
    }
    colElementRef.current = findParentElementFromClassName(event.target, gridClasses.columnHeader);
    const field = getFieldFromHeaderElem(colElementRef.current);
    const colDef = apiRef.current.getColumn(field);
    colGroupingElementRef.current = findGroupHeaderElementsFromField((_apiRef$current$colum = apiRef.current.columnHeadersContainerElementRef) == null ? void 0 : _apiRef$current$colum.current, field);
    logger.debug(`Start Resize on col ${colDef.field}`);
    apiRef.current.publishEvent('columnResizeStart', {
      field
    }, event);
    colDefRef.current = colDef;
    colElementRef.current = findHeaderElementFromField((_apiRef$current$colum2 = apiRef.current.columnHeadersElementRef) == null ? void 0 : _apiRef$current$colum2.current, colDef.field);
    colCellElementsRef.current = findGridCellElementsFromCol(colElementRef.current, apiRef.current);
    resizeDirection.current = getResizeDirection(event.target, theme.direction);
    initialOffsetToSeparator.current = computeOffsetToSeparator(touch.clientX, colElementRef.current.getBoundingClientRect(), resizeDirection.current);
    const doc = ownerDocument(event.currentTarget);
    doc.addEventListener('touchmove', handleTouchMove);
    doc.addEventListener('touchend', handleTouchEnd);
  });
  const stopListening = React.useCallback(() => {
    const doc = ownerDocument(apiRef.current.rootElementRef.current);
    doc.body.style.removeProperty('cursor');
    doc.removeEventListener('mousemove', handleResizeMouseMove);
    doc.removeEventListener('mouseup', handleResizeMouseUp);
    doc.removeEventListener('touchmove', handleTouchMove);
    doc.removeEventListener('touchend', handleTouchEnd);
    // The click event runs right after the mouseup event, we want to wait until it
    // has been canceled before removing our handler.
    setTimeout(() => {
      doc.removeEventListener('click', preventClick, true);
    }, 100);
    if (colElementRef.current) {
      colElementRef.current.style.pointerEvents = 'unset';
    }
  }, [apiRef, colElementRef, handleResizeMouseMove, handleResizeMouseUp, handleTouchMove, handleTouchEnd]);
  const handleResizeStart = React.useCallback(({
    field
  }) => {
    apiRef.current.setState(state => _extends({}, state, {
      columnResize: _extends({}, state.columnResize, {
        resizingColumnField: field
      })
    }));
    apiRef.current.forceUpdate();
  }, [apiRef]);
  const handleResizeStop = React.useCallback(() => {
    apiRef.current.setState(state => _extends({}, state, {
      columnResize: _extends({}, state.columnResize, {
        resizingColumnField: ''
      })
    }));
    apiRef.current.forceUpdate();
  }, [apiRef]);
  const handleColumnResizeMouseDown = useEventCallback(({
    colDef
  }, event) => {
    var _apiRef$current$colum3, _apiRef$current$heade, _apiRef$current$colum4;
    // Only handle left clicks
    if (event.button !== 0) {
      return;
    }

    // Skip if the column isn't resizable
    if (!event.currentTarget.classList.contains(gridClasses['columnSeparator--resizable'])) {
      return;
    }

    // Avoid text selection
    event.preventDefault();
    logger.debug(`Start Resize on col ${colDef.field}`);
    apiRef.current.publishEvent('columnResizeStart', {
      field: colDef.field
    }, event);
    colDefRef.current = colDef;
    colElementRef.current = (_apiRef$current$colum3 = apiRef.current.columnHeadersContainerElementRef) == null ? void 0 : _apiRef$current$colum3.current.querySelector(`[data-field="${colDef.field}"]`);
    const headerFilterRowElement = (_apiRef$current$heade = apiRef.current.headerFiltersElementRef) == null ? void 0 : _apiRef$current$heade.current;
    if (headerFilterRowElement) {
      headerFilterElementRef.current = headerFilterRowElement.querySelector(`[data-field="${colDef.field}"]`);
    }
    colGroupingElementRef.current = findGroupHeaderElementsFromField((_apiRef$current$colum4 = apiRef.current.columnHeadersContainerElementRef) == null ? void 0 : _apiRef$current$colum4.current, colDef.field);
    colCellElementsRef.current = findGridCellElementsFromCol(colElementRef.current, apiRef.current);
    const doc = ownerDocument(apiRef.current.rootElementRef.current);
    doc.body.style.cursor = 'col-resize';
    resizeDirection.current = getResizeDirection(event.currentTarget, theme.direction);
    initialOffsetToSeparator.current = computeOffsetToSeparator(event.clientX, colElementRef.current.getBoundingClientRect(), resizeDirection.current);
    doc.addEventListener('mousemove', handleResizeMouseMove);
    doc.addEventListener('mouseup', handleResizeMouseUp);

    // Prevent the click event if we have resized the column.
    // Fixes https://github.com/mui/mui-x/issues/4777
    doc.addEventListener('click', preventClick, true);
  });
  const handleColumnSeparatorDoubleClick = useEventCallback((params, event) => {
    if (props.disableAutosize) {
      return;
    }

    // Only handle left clicks
    if (event.button !== 0) {
      return;
    }
    const column = apiRef.current.state.columns.lookup[params.field];
    if (column.resizable === false) {
      return;
    }
    apiRef.current.autosizeColumns(_extends({}, props.autosizeOptions, {
      columns: [column.field]
    }));
  });

  /**
   * API METHODS
   */

  const columnVirtualizationDisabled = useColumnVirtualizationDisabled(apiRef);
  const isAutosizingRef = React.useRef(false);
  const autosizeColumns = React.useCallback(async userOptions => {
    var _apiRef$current$rootE, _userOptions$columns;
    const root = (_apiRef$current$rootE = apiRef.current.rootElementRef) == null ? void 0 : _apiRef$current$rootE.current;
    if (!root) {
      return;
    }
    if (isAutosizingRef.current) {
      return;
    }
    isAutosizingRef.current = true;
    const state = gridColumnsStateSelector(apiRef.current.state);
    const options = _extends({}, DEFAULT_GRID_AUTOSIZE_OPTIONS, userOptions, {
      columns: (_userOptions$columns = userOptions == null ? void 0 : userOptions.columns) != null ? _userOptions$columns : state.orderedFields
    });
    options.columns = options.columns.filter(c => state.columnVisibilityModel[c] !== false);
    const columns = options.columns.map(c => apiRef.current.state.columns.lookup[c]);
    try {
      apiRef.current.unstable_setColumnVirtualization(false);
      await columnVirtualizationDisabled();
      const widthByField = extractColumnWidths(apiRef, options, columns);
      const newColumns = columns.map(column => _extends({}, column, {
        width: widthByField[column.field],
        computedWidth: widthByField[column.field]
      }));
      if (options.expand) {
        var _apiRef$current$getRo, _apiRef$current$getRo2;
        const visibleColumns = state.orderedFields.map(field => state.lookup[field]).filter(c => state.columnVisibilityModel[c.field] !== false);
        const totalWidth = visibleColumns.reduce((total, column) => {
          var _ref, _widthByField$column$;
          return total + ((_ref = (_widthByField$column$ = widthByField[column.field]) != null ? _widthByField$column$ : column.computedWidth) != null ? _ref : column.width);
        }, 0);
        const availableWidth = (_apiRef$current$getRo = (_apiRef$current$getRo2 = apiRef.current.getRootDimensions()) == null ? void 0 : _apiRef$current$getRo2.viewportInnerSize.width) != null ? _apiRef$current$getRo : 0;
        const remainingWidth = availableWidth - totalWidth;
        if (remainingWidth > 0) {
          const widthPerColumn = remainingWidth / (newColumns.length || 1);
          newColumns.forEach(column => {
            column.width += widthPerColumn;
            column.computedWidth += widthPerColumn;
          });
        }
      }
      apiRef.current.updateColumns(newColumns);
    } finally {
      apiRef.current.unstable_setColumnVirtualization(true);
      isAutosizingRef.current = false;
    }
  }, [apiRef, columnVirtualizationDisabled]);

  /**
   * EFFECTS
   */

  React.useEffect(() => stopListening, [stopListening]);
  useOnMount(() => {
    if (props.autosizeOnMount) {
      Promise.resolve().then(() => {
        apiRef.current.autosizeColumns(props.autosizeOptions);
      });
    }
  });
  useGridNativeEventListener(apiRef, () => {
    var _apiRef$current$colum5;
    return (_apiRef$current$colum5 = apiRef.current.columnHeadersElementRef) == null ? void 0 : _apiRef$current$colum5.current;
  }, 'touchstart', handleTouchStart, {
    passive: doesSupportTouchActionNone()
  });
  useGridApiMethod(apiRef, {
    autosizeColumns
  }, 'public');
  useGridApiEventHandler(apiRef, 'columnResizeStop', handleResizeStop);
  useGridApiEventHandler(apiRef, 'columnResizeStart', handleResizeStart);
  useGridApiEventHandler(apiRef, 'columnSeparatorMouseDown', handleColumnResizeMouseDown);
  useGridApiEventHandler(apiRef, 'columnSeparatorDoubleClick', handleColumnSeparatorDoubleClick);
  useGridApiOptionHandler(apiRef, 'columnResize', props.onColumnResize);
  useGridApiOptionHandler(apiRef, 'columnWidthChange', props.onColumnWidthChange);
};