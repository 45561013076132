import Helpers from './helpers';

const handleCreate = async (setState) => {
  setState.setEditMode('create');
  setState.setRowEditing(null);
  setState.setProviderId(null);
  setState.setOwnerId(null);
  setState.setConsumerId(null);
  setState.setName('');
  setState.setDescription('');
  setState.setCron('');

  setState.setEditOpen(true);
};

const handleCreateSubmit = async (values, state, setState, dataStreamFields, postDataStream, schema) => {
  await Helpers.createDataStream(values, state, setState, dataStreamFields, postDataStream, schema);
  setState.setEditOpen(false);
};

const handleDialogOpen = async (event, fieldName, row, setState) => {
  if (fieldName === 'provider_schema') {
    setState.setDialogContent(JSON.stringify(row.provider_schema, undefined, 2));
    setState.setDialogTitle('Provider JSON Schema');
  }
  else if (fieldName === 'provider_properties') {
    setState.setDialogContent(JSON.stringify(row.provider_properties, undefined, 2));
    setState.setDialogTitle('Provider Properties');
  }
  else if (fieldName === 'data_stream_provider_properties') {
    setState.setDialogContent(JSON.stringify(row.data_stream_provider_properties, undefined, 2));
    setState.setDialogTitle('Data Stream Properties');
  }
  setState.setDialogOpen(true);
};

const handleDialogClose = (setState) => {
  setState.setDialogOpen(false);
};

const handleEdit = async (event, row, queryClient, setState) => {
  setState.setEditMode('edit');
  setState.setRowEditing(row);
  setState.setCron(row.cron);
  setState.setLastRetrievalDate(row.last_retrieval_date);
  setState.setProviderId(row.provider_id);
  setState.setOwnerId(row.owner_id);

  setState.setDataStreamId(row.id);
  await queryClient.refetchQueries('getOwnerConsumerDefaults');

  setState.setEditOpen(true);
};

const handleEditSubmit = async (values, queryClient, state, setState, putDataStream, schema) => {

  await Helpers.updateDataStream(values, state, putDataStream, schema);

  await queryClient.invalidateQueries('getDataStreams');

  setState.setEditOpen(false);
};

const refreshTable = async (queryClient) => {
  await queryClient.invalidateQueries('getDataStreams');
  console.info('Table refreshed');
};


export default {handleCreate, handleCreateSubmit, handleDialogOpen, handleDialogClose, handleEdit, handleEditSubmit,
  refreshTable};

