import {TextField} from '@mui/material';

/**
 * A multiline text field with monospace font and good helper text and spacing
 */
const IyoFormikCodeEditor = ({name, label, values, touched, errors, handleBlur, handleChange, rows,
                            required = false, disabled = false}) => {
  return (
    <TextField
      name={name}
      label={label}
      value={values[name]}
      error={Boolean(touched[name] && errors[name])}
      helperText={Boolean(touched[name] && errors[name]) ? '\u25B4 ' + errors[name] : ' '}
      FormHelperTextProps={{sx: {textAlign: 'right', lineHeight: 1, mx: 0, my: '0.125rem'}}}
      fullWidth
      onBlur={handleBlur}
      onChange={handleChange}
      variant='outlined'
      required={required}
      disabled={disabled}
      multiline
      rows={rows}
      InputProps={{
        sx: {
          fontFamily: 'monospace',
          fontSize: '0.9rem'
        }
      }}
    />
  );
};

export default IyoFormikCodeEditor;