import axios from 'axios';
import {fetchConfig} from '../util/util';

const HOST_URL = fetchConfig('hostUrl')

const getExecutions = async (params) => {
  return (await axios.get(`${HOST_URL}/log-execution`, {params: params})).data;
};

const getLogs = async (executionId) => {
  return (await axios.get(`${HOST_URL}/log-aggregation/${executionId}`)).data;
};

export default {getExecutions, getLogs};