import {Alert} from '@mui/material';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';

/**
 *
 * @param displayText text to display
 * @param duration count in milliseconds to show error
 * @param open true if popup should be open
 * @param setOpen DEPRECATED sets the boolean (really the onClose should be handling this)
 * @param onClose callback to perform on popup close
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorPopup = ({displayText, duration, open, setOpen = () => {}, onClose}) => {
  let hideDuration = duration ? duration : 60000;

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      autoHideDuration={hideDuration}
    >
      <Alert onClose={handleClose} severity='error'>
        {displayText}
      </Alert>
    </Snackbar>
  );
};

ErrorPopup.propTypes = {
  displayText: PropTypes.string,
  duration: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default ErrorPopup;