const mockOwners = [
  {
    id: 1,
    name: 'Cardi B\'s Cows',
    address_1: '123 Bovine Lane',
    address_2: 'Unit 1',
    city: 'Cowville',
    state: 'NY',
    zipcode: '12345',
    latitude: '50.0',
    longitude: '-75.0'
  },
  {
    id: 2,
    name: 'Megan\'s Stallions',
    address_1: '123 Equine Way',
    address_2: '',
    city: 'Horsetown',
    state: 'VA',
    zipcode: '54321',
    latitude: '35.0',
    longitude: '-75.0'
  },
  {
    id: 3,
    name: 'Adam\'s Animals',
    address_1: '123 Beast Blvd',
    address_2: '',
    city: 'Animalia',
    state: 'CO',
    zipcode: '80123',
    latitude: '40.0',
    longitude: '-105.0'
  }
]

export {mockOwners};