import React from 'react';
import dayjs from 'dayjs';
import StartTimePicker from './header-filters/StartTimePicker';
import ProviderNameSelect from './header-filters/ProviderNameSelect';
import StatusSelect from './header-filters/StatusSelect';
import OwnerNameSelect from './header-filters/OwnerNameSelect';
import LastLambdaSelect from './header-filters/LastLambdaSelect';

export const columns = [
  {
    field: 'execution_id',
    headerName: 'Execution ID',
    filterable: false,
    sortable: false,
    minWidth: 250
  },
  {
    field: 'provider_name',
    headerName: 'Provider',
    minWidth: 200,
    type: 'singleSelect',
    renderHeaderFilter: params => <ProviderNameSelect {...params}/>
  },
  {
    field: 'owner_name',
    headerName: 'Owner',
    type: 'singleSelect',
    renderHeaderFilter: params => <OwnerNameSelect {...params}/>,
    minWidth: 200
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 200,
    type: 'singleSelect',
    renderHeaderFilter: params => <StatusSelect {...params}/>
  },
  {
    field: 'last_lambda_function',
    headerName: 'Last Lambda',
    minWidth: 200,
    type: 'singleSelect',
    renderHeaderFilter: params => <LastLambdaSelect {...params}/>
  },
  {
    field: 'start_time',
    headerName: 'Start Time',
    description: 'Return executions since selected time',
    type: 'dateTime',
    valueFormatter: ({value}) => value && dayjs(value).format('ddd M/D/YYYY, h:mm:ss A'),
    renderHeaderFilter: params => <StartTimePicker {...params}/>,
    minWidth: 250
  },
  {
    field: 'end_time',
    headerName: 'End Time',
    type: 'dateTime',
    filterable: false,
    sortable: false,
    valueFormatter: ({value}) => value && dayjs(value).format('ddd M/D/YYYY, h:mm:ss A'),
    minWidth: 250
  },
  {
    field: 'duration',
    headerName: 'Duration',
    filterable: false,
    sortable: false,
    minWidth: 150
  }
];