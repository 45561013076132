import { useQuery, useMutation } from 'react-query';
import DataStreamService from './DataStreamService';
import Helpers from './helpers';
import OwnerConsumersService from '../../../apis/OwnerConsumersService';

const useDataStreamQuery = (method, queryClient, state, setState, input) => {
  switch (method) {
    case 'GET':
      return useQuery(
        'getDataStreams',
        async () => {
          return await DataStreamService.getAll();
        },
        {
          onError: (error) => {
            Helpers.produceError(error, setState);
          },
          staleTime: Infinity,
        }
      );
    case 'POST':
      return useMutation(
        'postDataStream',
        async (data) => {
          return await DataStreamService.post(data);
        },
        {
          onSuccess: async (resp) => {
            setState.setDataStreamId(resp);
            await queryClient.invalidateQueries('getDataStreams');
          },
          onError: (error) => {
            Helpers.produceError(error, setState);
          },
        }
      );
    case 'PUT':
      return useMutation(
        'putDataStream',
        async (data) => {
          let myData = JSON.parse(JSON.stringify(data));
          if (!myData.last_retrieval_date) {
            myData.last_retrieval_date = null;
          }
          return await DataStreamService.put(state.rowEditing.id, myData);
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries('getDataStreams');
          },
          onError: (error) => {
            Helpers.produceError(error, setState);
          },
        }
      );
    default:
      return;
  }
};

const useOwnerDefaultDataStreamQuery = (method, state, setState) => {
  switch (method) {
    case 'GET':
      return useQuery(
        ['getOwnerConsumerDefaults', state.ownerId],
        async () => await OwnerConsumersService.get(state.ownerId),
        {
          onSuccess: (data) => {
            setState.setConsumerId(data.map((rec) => rec.consumer_id));
            setState.setOriginalConsumers(data.map((rec) => rec.consumer_id));
          },
          onError: (error) => {
            Helpers.produceError(error, setState);
          },
          staleTime: Infinity,
        }
      );
    default:
      return;
  }
};

export default { useDataStreamQuery, useOwnerDefaultDataStreamQuery };
