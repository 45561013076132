import {useMutation} from 'react-query';
import ProviderService from '../../../apis/ProviderService';

const useProviderQuery = (method, queryClient, state, setState, produceError) => {
  switch (method) {
    case 'POST':
      return useMutation(
        'postProvider',
        async (data) => {
          return await ProviderService.post(data);
        },
        {
          onSuccess: async (resp) => {
            setState.setProviderId(resp);
            setState.setMsgText(`Provider created, id = ${resp.id}`);
            setState.setMsgOpen(true);
            await queryClient.invalidateQueries('getProvidersPage');
          },
          onError: (error) => {
            produceError(error, setState);
          },
        }
      );
    case 'PUT':
      return useMutation('putProvider', async (data) => {
        return await ProviderService.put(state.rowEditing.id, data);
      }, {
        onSuccess: async () => {
          setState.setMsgText(`Provider ${state.rowEditing.name} updated`);
          setState.setMsgOpen(true);
          await queryClient.invalidateQueries('getProvidersPage');
        },
        onError: (error) => {
          produceError(error, setState);
        }
      });
    default:
      return;
  }
};

export default {useProviderQuery};