import Helpers from './helpers';

const boolRegex = /^(?:(1|t(?:rue)?)|(0|f(?:alse)?))$/i;
const intRegex = /^\d+$/;

const handleClose = (setState) => {
  setState.setName('');
  setState.setDescription('');
  setState.setEditOpen(false);
};

const handleConsumerSelect = (selection, state, setState) => {
  let consumers = JSON.parse(JSON.stringify(state.consumerId || []));

  if (consumers.includes(selection.value)) {
    consumers = consumers.filter(id => id !== selection.value);
  }
  else {
    consumers.push(selection.value);
  }

  setState.setConsumerId(consumers);
};

const handleOwnerSelect = (selection, setState) => {
  setState.setOwnerId(selection.value);
};

const handleCronUpdate = (value, setState) => {
  setState.setCron(value);
};

const handleProviderSelect = async (selection, values, resetForm, setState, getProperties) => {
  setState.setProviderId(selection.value);
  setState.setName(values.name);
  setState.setDescription(values.description);
  setState.setCron(values.cron);
  setState.setLastRetrievalDate(values.last_retrieval_date);

  const newFields = !getProperties.data ? null : getProperties.data;

  resetForm({
    values: {
      name: values.name,
      description: values.description,
      provider_id: selection.value,
      ...Helpers.prettifyProperties(newFields)
    }
  });
};

const validate = async (values, getProperties) => {
  const fields = !getProperties.data ? null : getProperties.data;

  const validationObject = Helpers.generateValidationSchema(fields);

  let errors = {};

  for (let field in validationObject) {
    try {
      await validationObject[field].validate(values[field]);

      switch (validationObject[field].type) {
        case "boolean":
          if (!boolRegex.test(values[field])) {
            errors[field] = `${values[field]} is not a valid boolean value for field ${field}`;
          }
          break;
        case "integer":
          if (!intRegex.test(values[field])) {
            errors[field] = `${values[field]} is not a valid numeric value for field ${field}`;
          }
          break;
      }

    } catch (err) {
      errors[field] = err.errors[0];
    }
    // TODO will probably have to turn an array of errors from Yup into a single string with commas or something (Formik doesn't tell you how to do multiple errors)
  }

  return errors;
};

export default {handleClose, handleConsumerSelect, handleOwnerSelect, handleCronUpdate, handleProviderSelect, validate};