import axios from 'axios';
import {fetchConfig} from '../util/util';

const HOST_URL = fetchConfig('hostUrl')

const getOneById = async (ownerId) => {
  return (await axios.get(`${HOST_URL}/owner/${ownerId}`)).data;
};

const getAll = async () => {
  return (await axios.get(`${HOST_URL}/owner`)).data;
};

const post = async (data) => {
  return (await axios.post(`${HOST_URL}/owner`, data)).data;
};

const put = async (ownerId, data) => {
  return (await axios.put(`${HOST_URL}/owner/${ownerId}`, data)).data;
};

export default {getOneById, getAll, post, put};