import React, {useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {Box, IconButton, Paper, Tooltip, LinearProgress, useTheme} from '@mui/material';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import Canvas from '../../navigation/canvas/Canvas';
import ErrorPopup from '../../common/ErrorPopup';
import MessagePopup from '../../common/MessagePopup';
import ProviderEditDialog from './ProviderEditDialog';
import ScrollableDialog from '../../common/ScrollableDialog';
import TableToolbar from '../../common/TableToolbar';
import ProviderService from '../../../apis/ProviderService';
import {columns} from './columns';

const Providers = ({}) => {
  // ####################[ Setup and Initialization ]####################
  const queryClient = useQueryClient();

  const initializeColumns = (initialColumns) => {
    return initialColumns.concat({
      field: 'actions',
      headerName: 'Actions',
      description: 'Actions',
      minWidth: 175,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title='View Schema'>
              <IconButton color='primary' onClick={(event) => handleDialogOpen(event, true, params.row)}>
                <InfoIcon/>
              </IconButton>
            </Tooltip>
            &nbsp;
            <Tooltip title='View Properties'>
              <IconButton color='primary' onClick={(event) => handleDialogOpen(event, false, params.row)}>
                <BuildCircleIcon/>
              </IconButton>
            </Tooltip>
            &nbsp;
            <Tooltip title='Edit'>
              <IconButton color='primary' onClick={(event) => handleEdit(event, params.row, queryClient, setState)}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </>
        );
      }
    });
  };

  // ####################[ State Definitions ]####################
  const [cols, setCols] = useState(initializeColumns(columns));
  const [dialogContent, setDialogContent] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [editMode, setEditMode] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [errDisplayText, setErrDisplayText] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState('');
  const [providerId, setProviderId] = useState(null);
  const [rowEditing, setRowEditing] = useState([]);

  const state = { editMode, editOpen, rowEditing, providerId };
  const setState = { setEditMode, setEditOpen, setErrorOpen, setErrDisplayText, setMsgOpen, setMsgText,
    setProviderId, setRowEditing };

  const formatData = (dataArray) => {
    let returnArray = [];
    for (let data of dataArray) {
      let rec = Object.assign({}, data);

      for (let field in rec)
        if (rec[field] === null)
          rec[field] = '';

      const boolFields = ['has_hwm', 'device_provider', 'timezone_sensitive', 'v3'];
      for (let field of boolFields) {
        if (rec[field] === 1)
          rec[field] = 'true';
        else if (rec[field] === 0)
          rec[field] = 'false';
      }

      const jsonFields = ['schema', 'properties'];
      for (let field of jsonFields)
        rec[field] = JSON.stringify(rec[field], null, 2);

      returnArray.push(rec);
    }
    return returnArray;
  };

  // ####################[ Query Definitions ]####################
  const getProviders = useQuery('getProvidersPage', async () => {
      return formatData(await ProviderService.getAll());
    },
    {
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity
    }
  );

  // ####################[ Event Handlers ]####################
  const handleCreate = async () => {
    setState.setEditMode('create');
    setState.setRowEditing(null);
    setState.setEditOpen(true);
  };

  const handleDialogOpen = async (event, isSchema, row) => {
    if (isSchema) {
      setDialogContent(row.schema);
      setDialogTitle('JSON Schema');
    }
    else {
      setDialogContent(row.properties);
      setDialogTitle('Properties');
    }
    setDialogOpen(true);
  };

  const handleDialogClose = async () => {
    setDialogOpen(false);
  };

  const handleEdit = async (event, row) => {
    setEditMode('edit');
    setRowEditing(row);
    setProviderId(row.id);
    setEditOpen(true);
  };

  const handleError = (error) => {
    setErrorOpen(false);
    setErrDisplayText(`${error.message}` +
      `${error.response?.data ? ': ' + JSON.stringify(error.response.data) : ''}`);
    setErrorOpen(true);

    console.error(`${error.message}: ${JSON.stringify(error.response.data)}`);
  };

  const refreshTable = async () => {
    await queryClient.invalidateQueries('getProvidersPage');
  };

  // ####################[ The Component ]####################
  return (
    <Box>
      <Canvas appName='nGEST' title='Providers'>
        <TableToolbar
          editHandler={() => handleCreate(setState)}
          onRefresh={refreshTable}
        />
        <Paper sx={{height: '77vh', '& .disabled': {color: '#b2b29b'}}}>
          <DataGridPro
            columns={cols}
            rows={getProviders.data || []}
            getRowClassName={(params) => params.row.disabled ? 'disabled' : ''}
            slots={{loadingOverlay: LinearProgress, toolbar: GridToolbar}}
            loading={getProviders.isLoading}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  description: false,
                  created_by: false,
                  created_datetime: false,
                  updated_by: false,
                  updated_datetime: false
                }
              }
            }}
            density='compact'
            sx={{
              '& .run-params-header': {backgroundColor: '#e9f7e3'},
              '& .bool-cell-true': {color: 'darkgreen'},
              '& .bool-cell-false': {color: 'darkred'}
            }}
          />
        </Paper>
      </Canvas>
      <ProviderEditDialog
        state={state}
        setState={setState}
      />
      <ScrollableDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        close={handleDialogClose}
        content={dialogContent}
        title={dialogTitle}
        scroll={'paper'}
      />
      <MessagePopup
        open={msgOpen}
        setOpen={setMsgOpen}
        displayText={msgText}
      />
      <ErrorPopup
        open={errorOpen}
        setOpen={setErrorOpen}
        displayText={errDisplayText}
      />
    </Box>
  );
};

export default Providers;