import React, {useState} from 'react';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import {Box, IconButton, LinearProgress, Paper, Tooltip} from '@mui/material';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import Canvas from '../../navigation/canvas/Canvas';
import CreateDeviceDialog from './CreateDeviceDialog';
import ErrorPopup from '../../common/ErrorPopup';
import TableToolbar from '../../common/TableToolbar';
import UploadLogsDialog from './UploadLogsDialog';
import IyoAuth from '../../../auth/Auth';
import deviceService from '../../../apis/DeviceService';
import DeviceService from '../../../apis/DeviceService';
import ownerService from '../../../apis/OwnersService';
import {ManageSearch} from '@mui/icons-material';
import {columns} from './deviceColumns';

const Devices = ({}) => {
  // ####################[ Setup and Initialization ]####################
  const queryClient = useQueryClient();

  const initializeColumns = (initialColumns) => {
    return initialColumns.concat({
      field: 'logs',
      headerName: 'UPLOAD LOGS',
      description: 'view the device upload history',
      minWidth: 250,
      renderCell: (params) => {
        return (
          params.row.active === 1 ?
            <Box>
              <Tooltip title='View Upload History'>
                <IconButton color='primary' onClick={(event) => handleLogsOpen(event, params.row)}>
                  <ManageSearch />
                </IconButton>
              </Tooltip>
            </Box> :
            null
        );
      }
    });
  };

  // ####################[ State Definitions ]####################
  const [cols, setCols] = useState(initializeColumns(columns));
  const [createOpen, setCreateOpen] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [macAddress, setMacAddress] = useState(null);
  const [errDisplayText, setErrDisplayText] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);

  // ####################[ Query Definitions ]####################
  const getDevices = useQuery('getDevices', async () => {
      return await deviceService.getDevices();
    },
    {
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity
    }
  );

  const getDeviceLogs = useQuery('getDeviceLogs', async () => {
      return await DeviceService.getDeviceLogs(macAddress);
    },
    {
      onSuccess: (data) => {
        // TODO: Return data sorted by date in the API
        return data.sort((a, b) => new Date(b.created_datetime) - new Date(a.created_datetime));
      },
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity,
      enabled: !!macAddress
    }
  );

  const getOwners = useQuery('getOwners', async () => {
      return await ownerService.getAll();
    },
    {
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity
    }
  );

  const postDevice = useMutation('postDevice', async (data) => {
    return await deviceService.createDevice(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getDevices');
      setCreateOpen(false);
    },
    onError: (error) => {
      setCreateOpen(false);
      handleError(error);
    }
  });

  const handleCreate = () => {
    setCreateOpen(true);
  };

  const onSubmit = async (data) => {
    postDevice.mutate({
      ...data,
      updated_by: await IyoAuth.getUser()
    });
  };

  const handleError = (error) => {
    setErrorOpen(false);
    setErrDisplayText(`${error.message}: ${JSON.stringify(error.response.data)}`);
    setErrorOpen(true);

    console.error(`${error.message}: ${JSON.stringify(error.response.data)}`);
  };

  const handleLogsOpen = async (event, row) => {
    setMacAddress(row.mac_address);
    setLogsOpen(true);
  }

  const handleLogsClose = () => {
    setMacAddress(null);
    setLogsOpen(false);
  }

  const refreshTable = async () => {
    console.info('Refreshing table');
    await queryClient.invalidateQueries('getDevices');
  };

  // ####################[ The Component ]####################
  return (
    <Box>
      <Canvas appName='nGEST' title='Devices'>
        <TableToolbar editHandler={handleCreate} onRefresh={refreshTable}/>
        <Paper sx={{height: '77vh', '& .disabled': {color: '#b2b29b'}}}>
          <DataGridPro
            columns={cols}
            rows={getDevices.data || []}
            getRowClassName={(params) => params.row.disabled ? 'disabled' : ''}
            slots={{loadingOverlay: LinearProgress, toolbar: GridToolbar}}
            loading={getDevices.isLoading}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  mac_address: false,
                  api_key: false,
                  activation_attempts: false,
                  backend_url: false,
                  activation_date: false,
                  created_by: false,
                  created_datetime: false,
                  updated_by: false,
                  updated_datetime: false
                }
              }
            }}
            density='compact'
          />
        </Paper>
      </Canvas>
      <CreateDeviceDialog
        open={createOpen}
        setOpen={setCreateOpen}
        owners={getOwners.data}
        onSubmit={onSubmit}
        handleError={handleError}
      />
      {
        !getDeviceLogs.data ?
          null :
          <UploadLogsDialog
            open={logsOpen}
            setOpen={setLogsOpen}
            close={handleLogsClose}
            logs={getDeviceLogs.data}
            handleError={handleError}
          />
      }
      <ErrorPopup
        open={errorOpen}
        setOpen={setErrorOpen}
        displayText={errDisplayText}
      />
    </Box>
  );
};

export default Devices;