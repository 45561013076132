import React, {useState} from 'react';
import {Box, Drawer, List, useTheme} from '@mui/material';
import {Business, Checklist, Dashboard, Devices,  DevicesOther, DriveFileMove, Gite, Upload}
  from '@mui/icons-material';
import SidebarLink from './SidebarLink';
import SidebarImage from './SidebarImage';
import {Home} from '../../icons';

const NavigationSideBar = () => {
  const theme = useTheme();
  const [_, setActivePath] = useState(window.location.pathname);

  const updateActiveLink = () => setActivePath(window.location.pathname);

  const itemSpacing = 8;

  return (
    <Drawer
      anchor='left'
      open
      variant='permanent'
      PaperProps={{
        sx: {
          boxSizing: 'border-box',
          boxShadow: 24,
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }
      }}
    >
      <SidebarImage />
      <Box sx={{overflow: 'auto'}}>
        <List>
          <SidebarLink itemSx={{mt: 4}} link={'/home'} label='HOME' icon={Home} postAction={updateActiveLink} />
          <SidebarLink itemSx={{mt: itemSpacing}} link={'/owners'} label='OWNERS' icon={Gite} postAction={updateActiveLink} />
          <SidebarLink itemSx={{mt: itemSpacing}} link={'/providers'} label='PROVIDERS' icon={Devices} postAction={updateActiveLink} />
          <SidebarLink itemSx={{mt: itemSpacing}} link={'/data-streams'} label='DATASTREAMS' icon={DriveFileMove} postAction={updateActiveLink} />
          <SidebarLink itemSx={{mt: itemSpacing}} link={'/consumers'} label='CONSUMERS' icon={Business} postAction={updateActiveLink} />
          <SidebarLink itemSx={{mt: itemSpacing}} link={'/consumerTypes'} label='CONSUMER TYPES' icon={Upload} postAction={updateActiveLink} />
          <SidebarLink itemSx={{mt: itemSpacing}} link={'/devices'} label='DEVICES' icon={DevicesOther} postAction={updateActiveLink} />
          <SidebarLink itemSx={{mt: itemSpacing}} link={'/data-flow-details'} label='DATA FLOW DETAILS' icon={Checklist} postAction={updateActiveLink} />
          <SidebarLink itemSx={{mt: itemSpacing}} link={'/dashboard'} label='DASHBOARD' icon={Dashboard} postAction={updateActiveLink} />
        </List>
      </Box>
    </Drawer>
)};

export default NavigationSideBar;
