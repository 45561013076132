import React, {useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {Typography} from '@mui/material';

/**
 * Sets up a route that is only accessible under specified conditions.
 * When making a new conditional route, make sure to double-check the redirect logic in both your new route, the
 * redirected route, and wherever that route may redirect.  It is very possible to end up in "Redirect Hell", where the
 * UI redirects to many different pages.  You can also end up in an infinite redirect loop this way.
 *
 * @param conditionCallback async callback function to determine accessibility.
 * @param render components to render when route is accessible.
 * @param failRedirect if inaccessible, specify where to redirect.  Defaults to 404 page.
 * @param loadMessage message to display while determining page accessibility.
 * @param props any additional props to pass the inner Route component.
 * @returns {JSX.Element} a conditionally accessible Route.
 * @constructor
 */
const ConditionalRoute = ({conditionCallback, render, failRedirect = '/404', loadMessage = 'Loading...',
                            ...props}) => {
  const [toRender, setToRender] = useState();

  useEffect(() => {
    conditionCallback().then(bool => setToRender(bool));
  }, [setToRender]);

  if (toRender === false) {
    return (<Redirect to={{pathname: failRedirect}} />);
  }

  else if (toRender === true) {
    const _render = render || (_ => children);
    return (
      <Route {...props} render={props => _render(props)} />
    )
  }

  else {
    return <Typography variant='h3'>{loadMessage}</Typography>;
  }
};

export default ConditionalRoute;
