import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {BottomNavigation, BottomNavigationAction, Paper} from '@mui/material';
import {Business, Checklist, Dashboard, Devices, DriveFileMove, Gite} from '@mui/icons-material';
import {Home} from '../../icons';

const NavigationBottomBar = () => {
  const [activeButton, setActiveButton] = useState(window.location.pathname);

  return (
    <Paper elevation={3} sx={{
      display: {
        xs: 'flex',
        md: 'none'
      },
      height: '4.3rem',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0
    }}>
    <BottomNavigation
      sx={{width: '100%', height: '100%'}}
      showLabels
      value={activeButton}
      onChange={(event, newValue) => {
        setActiveButton(newValue);
      }}
    >
      <BottomNavigationAction label='Home' icon={<Home />} component={RouterLink} to='/' />
      <BottomNavigationAction label='Owners' icon={<Gite/>} component={RouterLink} to='/owners' />
      <BottomNavigationAction label='Providers' icon={<Devices/>} component={RouterLink} to='/providers' />
      <BottomNavigationAction label='Consumers' icon={<Business/>} component={RouterLink} to='/consumers' />
      <BottomNavigationAction label='Data Streams' icon={<DriveFileMove/>} component={RouterLink} to='/data-streams' />
      <BottomNavigationAction label='Data Flow Details' icon={<Checklist/>} component={RouterLink} to='/data-flow-details' />
      <BottomNavigationAction label='Dashboard' icon={<Dashboard/>} component={RouterLink} to='/dashboard' />
    </BottomNavigation>
    </Paper>
  )
};

export default NavigationBottomBar;