import React from 'react';
import {useForm, Controller} from 'react-hook-form';
import {Button, Dialog, DialogTitle, Grid, MenuItem, Select} from '@mui/material';

const CreateDeviceDialog = (props) => {
  const {control, handleSubmit} = useForm();

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <DialogTitle>
        'Create Device for Owner'
      </DialogTitle>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <Controller
              name='owner_id'
              control={control}
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <Select
                  labelId='owner-label'
                  id='owner-id'
                  value={value}
                  label='Owner'
                  defaultValue=''
                  displayEmpty
                  renderValue={(value) => {
                    if (value === '') {
                      return 'Please select owner to create device for...'
                    } else {
                      return props.owners.find(x => x.id === value).name;
                    }
                  }}
                  onChange={onChange}
                >
                  {
                    props.owners.map((owner) =>
                      <MenuItem key={owner.id} value={owner.id}>{owner.name}</MenuItem>
                    )
                  }
                </Select>
              )}
              rules={{required: true}}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color='primary'
              onClick={handleClose}
              size='large'
              type='button'
              variant='outlined'
            >
              Cancel
            </Button>
            &nbsp;
            <Button
              color='primary'
              size='large'
              type='submit'
              variant='contained'
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default CreateDeviceDialog;