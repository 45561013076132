import React from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {Button, Dialog, DialogTitle, Divider, Grid, Typography} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import IyoAuth from '../../../auth/Auth';
import ProviderHooks from './ProviderHooks';
import DataStreamCron from '../data-streams/data-stream-cron/DataStreamCron';
import IyoFormikTextField from '../../common/IyoFormikTextField';
import IyoFormikDropDown from '../../common/IyoFormikDropDown';
import IyoFormikCodeEditor from '../../common/IyoFormikCodeEditor';

const ProviderEditDialog = ({state, setState}) => {
  const queryClient = useQueryClient();

  // ####################[ Configs ]####################
  const DataTypeName = 'Provider';

  const trueFalseDataList = [
    {name: 'true', value: 'true'},
    {name: 'false', value: 'false'}
  ];

  const hwmFormatDataList = [
    {id: 0, name: 'YYYY-MM-DD hh:mm:ss.SSS', value: 'YYYY-MM-DD hh:mm:ss.SSS'},
    {id: 1, name: 'YYYY-MM-DD hh:mm:ss', value: 'YYYY-MM-DD hh:mm:ss'},
    {id: 2, name: 'YYYY-MM-DDThh:mm:ssZ (UTC)', value: 'YYYY-MM-DDThh:mm:ssZ'},
    {id: 3, name: 'YYYY-MM-DD', value: 'YYYY-MM-DD'}
  ];

  // ####################[ Helpers ]####################
  const produceError = (error, setState) => {
    const message = `${error.message}` +
      `${error.response?.data ? ': ' + JSON.stringify(error.response.data) : ''}`;

    setState.setErrorOpen(false);
    setState.setErrDisplayText(message);
    setState.setErrorOpen(true);

    console.error(message);
  };

  const generateValidationSchema = (values) => {
    const schema = {
      name: Yup.string().required('Required'),
      schema: Yup.string().required('Required'),
      properties: Yup.string().required('Required'),
      has_hwm: Yup.string().required('Required'),
      device_provider: Yup.string().required('Required')
    };

    if (values.device_provider === 'false') {
      schema.timezone_sensitive = Yup.string().required('Required');

      schema.cron = Yup.string().required('Required');

      if (values.concurrency_limit === '')
        schema.concurrency_limit = Yup.string().required('Required');
      else
        schema.concurrency_limit = Yup.number().moreThan(0);

      if (values.backoff_interval === '')
        schema.backoff_interval = Yup.string().required('Required');
      else
        schema.backoff_interval = Yup.number().positive();
    }

    return schema;
  };

  const cleanPost = async (values) => {
    let body = {};

    for (let field in values)
      if (values[field] !== '')
        body[field] = values[field];

    const boolFields = ['has_hwm', 'device_provider', 'timezone_sensitive', 'v3'];
    for (let field of boolFields) {
      if (body[field] === 'true')
        body[field] = true;
      else if (body[field] === 'false')
        body[field] = false;
    }

    if (values.timezone_sensitive === '')
      body['timezone_sensitive'] = false;  // Because it's required in the DB but shouldn't be

    const jsonFields = ['schema', 'properties'];
    for (let field of jsonFields)
      body[field] = JSON.parse(body[field]);

    const numFields = ['concurrency_limit', 'backoff_interval'];
    for (let field of numFields)
      if (body[field])
        body[field] = Number(body[field]);

    if (body['has_hwm'] === true && !body['hwm_format'])
      body['hwm_format'] = 'YYYY-MM-DD hh:mm:ss.SSS';
    else if (body['has_hwm'] === false && body['hwm_format'])
      body['hwm_format'] = null;

    if (body['device_provider'] === true) {
      body['cron'] = null;
      body['concurrency_limit'] = null;
      body['backoff_interval'] = null;
    }

    body['created_by'] = await IyoAuth.getUser();
    body['updated_by'] = await IyoAuth.getUser();

    return body;
  };

  const cleanPut = async (values) => {
    let body = {};

    for (let field in values)
      if (values[field] !== state.rowEditing[field])
        body[field] = values[field] === '' ? null : values[field];

    if (Object.entries(body).length === 0)
      return undefined;

    body['id'] = state.rowEditing.id;
    body['updated_by'] = await IyoAuth.getUser();

    const boolFields = ['has_hwm', 'device_provider', 'timezone_sensitive', 'v3'];
    for (let field of boolFields) {
      if (body[field] === 'true')
        body[field] = true;
      else if (body[field] === 'false')
        body[field] = false;
    }

    const jsonFields = ['schema', 'properties'];
    for (let field of jsonFields)
      if (body[field])
        body[field] = JSON.parse(body[field]);

    if (body['has_hwm'] === true) {
      if (values.hwm_format === '')
        body['hwm_format'] = 'YYYY-MM-DD hh:mm:ss.SSS';
      else
        body['hwm_format'] = values.hwm_format;
    }
    else if (body['has_hwm'] === false)
      body['hwm_format'] = null;

    if (body['device_provider'] === true) {
      body['cron'] = null;
      body['concurrency_limit'] = null;
      body['backoff_interval'] = null;
    }

    return body;
  };

  // ####################[ Handlers and Queries ]####################
  const pageFn = {
    handleCreateSubmit: async (values, postProvider, state, setState) => {
      const body = await cleanPost(values);
      await postProvider.mutate(body);
      setState.setEditOpen(false);
    },
    handleEditSubmit: async (values, queryClient, putProvider, state, setState) => {
      const body = await cleanPut(values);
      if (!body)
        setState.setEditOpen(false);
      else {
        await putProvider.mutate(body);
        setState.setEditOpen(false);
      }
    },
    handleClose: async (setState) => {
      setState.setEditOpen(false);
    },
    validate: async (values) => {
      const validationObject = generateValidationSchema(values);

      let errors = {};
      for (let field in validationObject) {
        try {
          await validationObject[field].validate(values[field]);
        } catch (err) {
          errors[field] = err.errors[0];
        }
      }

      // Yup doesn't have a JSON string validation option (at least not one that works)
      const boolFields = ['schema', 'properties'];
      for (let field of boolFields) {
        try {
          JSON.parse(values[field])
        }
        catch (err) {
          if (errors[field] !== 'Required')
            errors[field] = 'Invalid JSON - ' + err.toString();
        }
      }

      return errors;
    },
    useInitialValuesQuery: (state, setState) => {
      return useQuery(['getInitialProviderValues', state.providerId, state.rowEditing], () => {
          const fields = ['name', 'description', 'schema', 'properties', 'has_hwm', 'hwm_format',
            'device_provider', 'cron', 'concurrency_limit', 'backoff_interval', 'timezone_sensitive', 'v3'];

          let values = {};

          for (let field of fields)
            values[field] = state.rowEditing?.[field] ?? '';

          // Put in default values to help the user
          if (state.editMode === 'create') {
            values['schema'] = JSON.stringify({
              type: 'object',
              title: 'Example',
              required: ['example'],
              properties: {example: {type: 'string', description: 'an example field'}},
              sensitiveValues: [],
              additionalProperties: false
            }, null , 2);

            values['properties'] = JSON.stringify({
              example: 'example field'
            }, null, 2);

            values['cron'] = '0 6 * * ? *';

            values['concurrency_limit'] = 10;

            values['backoff_interval'] = 2;
          }

          return values;
        },
        {
          onError: (error) => {
            produceError(error, setState);
          },
          staleTime: Infinity,
        }
      )
    }
  };

  const getInitialValues = pageFn.useInitialValuesQuery(state, setState);
  const putProvider = ProviderHooks.useProviderQuery('PUT', queryClient, state, setState,
    produceError);
  const postProvider = ProviderHooks.useProviderQuery('POST', queryClient, state, setState,
    produceError)

  // ####################[ Component ]####################
  return (
    <Dialog
      open={state.editOpen}
      onClose={() => pageFn.handleClose(setState)}
      maxWidth='md'
      fullWidth={true}
    >
      <DialogTitle>
        {
          state.editMode === 'edit' ? `Edit ${DataTypeName} : id = ${state.rowEditing.id}` :
            state.editMode === 'create' ? `Create ${DataTypeName}` : ''
        }
      </DialogTitle>
      {
        !getInitialValues.data ?
          null :
          <Formik
            initialValues={getInitialValues.data}

            validate={values => pageFn.validate(values)}

            onSubmit={state.editMode === 'edit' ? values => pageFn.handleEditSubmit(values, queryClient,
                putProvider, state, setState) :
              state.editMode === 'create' ? values => pageFn.handleCreateSubmit(values, postProvider,
                  state, setState) :
                () => {}}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                resetForm,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <div style={{margin: '2%'}}>
                  <Grid container rowSpacing={0} columnSpacing={2}>
                    {/* ##########################[ Row 1 ]############################## */}
                    <Grid item xs={6}>
                      <IyoFormikTextField
                        name='name'
                        label='Name'
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row 2 ]############################## */}
                    <Grid item xs={6}>
                      <IyoFormikTextField
                        name='description'
                        label='Description'
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row 3 ]############################## */}
                    <Grid item xs={12}>
                      <IyoFormikCodeEditor
                        name='schema'
                        label='Schema'
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        rows={15}
                        required
                      />
                    </Grid>
                    {/* ##########################[ Row 4 ]############################## */}
                    <Grid item xs={12}>
                      <IyoFormikCodeEditor
                        name='properties'
                        label='Properties'
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        rows={5}
                        required
                      />
                    </Grid>
                    {/* ##########################[ Row 5 -- HWM ]############################## */}
                    <Grid item xs={6}>
                      <IyoFormikDropDown
                        name='has_hwm'
                        label='Has HWM'
                        dataList={trueFalseDataList}
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IyoFormikDropDown
                        name='hwm_format'
                        label='HWM Format'
                        dataList={hwmFormatDataList}
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        disabled={values.has_hwm === 'false'}
                      />
                    </Grid>
                    {/* ##########################[ Row 6 ]############################## */}
                    <Grid item xs={6}>
                      <IyoFormikDropDown
                        name='device_provider'
                        label='Device Provider'
                        dataList={trueFalseDataList}
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IyoFormikDropDown
                        name='v3'
                        label='Version 3'
                        dataList={trueFalseDataList}
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        required
                      />
                    </Grid>
                    {/* ##########################[ Divider ]############################## */}
                    <Grid item xs={12}>
                      <Divider sx={{mb: '1rem'}}>
                        <Typography variant='body2'>
                          Run Parameters
                        </Typography>
                      </Divider>
                    </Grid>
                    {/* ##########################[ Row 7 ]############################## */}
                    <Grid item xs={6}>
                      <IyoFormikDropDown
                        name='timezone_sensitive'
                        label='Timezone Sensitive'
                        dataList={trueFalseDataList}
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        required={values.device_provider === 'false'}
                        disabled={values.device_provider === 'true'}
                      />
                    </Grid>
                    {/* ##########################[ Row 8 ]############################## */}
                    <Grid item xs={12}>
                      <DataStreamCron
                        fieldName='cron'
                        handleChange={handleChange('cron')}
                        handleBlur={handleBlur}
                        cronValue={values.cron}
                        errors={errors}
                        touched={touched}
                        required={values.device_provider === 'false'}
                        disabled={values.device_provider === 'true'}
                      />
                    </Grid>
                    {/* ##########################[ Row 9 ]############################## */}
                    <Grid item xs={6}>
                      <IyoFormikTextField
                        name='concurrency_limit'
                        label='Concurrency Limit'
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        numeric
                        required={values.device_provider === 'false'}
                        disabled={values.device_provider === 'true'}
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row 10 ]############################## */}
                    <Grid item xs={6}>
                      <IyoFormikTextField
                        name='backoff_interval'
                        label='Backoff Interval (seconds)'
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        numeric
                        required={values.device_provider === 'false'}
                        disabled={values.device_provider === 'true'}
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row Submit ]############################## */}
                    <Grid item xs={6} sx={{mt: '1rem'}}>
                      <Button
                        color='primary'
                        disabled={isSubmitting}
                        onClick={() => pageFn.handleClose(setState)}
                        size='large'
                        type='button'
                        variant='outlined'
                        sx={{mr: '1rem'}}
                      >
                        Cancel
                      </Button>
                      <Button
                        color='primary'
                        disabled={isSubmitting || Object.keys(errors).length !== 0}
                        size='large'
                        type='submit'
                        variant='contained'
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={6}/>
                  </Grid>
                </div>
              </form>
            )}
          </Formik>
      }
    </Dialog>
  );
};

export default ProviderEditDialog;