import React from 'react';
import {FormControl, Select, MenuItem} from '@mui/material';
import {useQuery} from 'react-query';
import OwnersService from '../../../../apis/OwnersService';
import {useHeaderFilter} from './useHeaderFilter';

const OwnerNameSelect = (props) => {
  const getOwnerNames = useQuery('getOwnerNames', async () => {
      const data = await OwnersService.getAll();
      return data.map(rec => rec.name).sort();
    },
      {
        staleTime: Infinity
      }
  );

  const {handleChange, value} = useHeaderFilter(props);

  return (
    <FormControl variant='standard' fullWidth>
      <Select
        value={value}
        onChange={handleChange}
      >
        {[
            <MenuItem key='' value=''><em>Any</em></MenuItem>,
            ...(getOwnerNames.data?.map(ownerName => (
              <MenuItem key={ownerName} value={ownerName}>{ownerName}</MenuItem>
            )) ?? [])
        ]}
      </Select>
    </FormControl>
  );
};

export default OwnerNameSelect;