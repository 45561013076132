import React, {useEffect} from 'react';
import TextField from '@mui/material/TextField';
import {Grid} from '@mui/material';
import Cronstrue from 'cronstrue';

const DataStreamCron = ({fieldName, handleChange, cronValue, errors,
                          required = false, disabled = false}) => {
  const selectId = fieldName.replace('_','-');
  const [cronMessage, setCronMessage] = React.useState('');
  const [isCronError, setIsCronError] = React.useState(false);

  // Minutes
  const minsRegex = /^([*]|(([0-5]?\d))|((([0-5]?\d))(\/|-)([0-5]?\d))|((([0-5]?\d))((,)([0-5]?\d))*))$/;
  // Hours
  const hrsRegex = /^([*]|[01]?\d|2[0-3]|(([01]?\d|2[0-3]?)(\/|-)([01]?\d|2[0-3]?))|(([01]?\d|2[0-3]?)((,)([01]?\d|2[0-3]?))*))$/;
  // Day of months
  const dayOfMonthRegex = /^([*]|[?]|(([1-9]|[12]\d|3[01])[LW]?)|(([1-9]|[12]\d|3[01])(\/|-)([1-9]|[12]\d|3[01]))|(([1-9]|[12]\d|3[01])((,)([1-9]|[12]\d|3[01]))*))$/;
  // Months
  const monthsRegex = /^([*]|([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|((([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))(\/|-)(([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))|((([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))((,)(([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))*))$/;
  // Day of Week
  const dayOfWeekRegex = /^([*]|[?]|([1-7]L?)|(SUN|MON|TUE|WED|THU|FRI|SAT)|((([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT))(\/|-|,|#)(([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)))|((([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT))((,)(([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)))*))$/;
  // Year
  const yearRegex = /^([*]|([1-2]\d{3})|(([1-2]\d{3})(\/|-)([1-2]\d{0,3}))|(([1-2]\d{3})((,)([1-2]\d{3}))*))$/

  function validateCron(cronValue, mountEvent) {
    const validationMsg = [];
    if (!cronValue || cronValue === '') {
      if (required === true && mountEvent === false)
        validationMsg.push('Required');
      else
        return validationMsg;
    }

    const splitValues = cronValue.trim().split(' ');
    function testRegex(val, field, regEx) {
      if (val !== '' && !regEx.test(val)) {
        validationMsg.push(`${val} is not valid for ${field}`);
      }
    }

    if ((splitValues.length !== 6 || splitValues[5].trim() === '')) {
      validationMsg.push(`${cronValue} is not in a valid AWS cron format`);
    } else {
      const mins = splitValues[0].trim();
      const hrs = splitValues[1].trim();
      const dayOfMonth = splitValues[2].trim();
      const months = splitValues[3].trim();
      const dayOfWeek = splitValues[4].trim();
      const year = splitValues[5].trim();

      if (dayOfMonth === '*' && dayOfWeek === '*') {
        validationMsg.push('Can\'t use * in both the Day-of-month and Day-of-week fields');
      }
      testRegex(mins, 'minutes', minsRegex);
      testRegex(hrs, 'hours', hrsRegex);
      testRegex(dayOfMonth, 'day of month', dayOfMonthRegex);
      testRegex(months, 'day of month', monthsRegex);
      testRegex(dayOfWeek, 'day of month', dayOfWeekRegex);
      testRegex(year, 'year', yearRegex);
    }

    const hasError = (validationMsg.length > 0);

    if (!hasError) {
      const cronMessage = Cronstrue.toString(cronValue.replace(/,/g, ' ').replace(/!/g, ','), {
        throwExceptionOnParseError: false,
        locale: 'en'
      });

      if (cronMessage && cronMessage !== '') {
        validationMsg.push(cronMessage);
      }
    }

    return {
      hasError: hasError,
      validationMsg: validationMsg
    }
  }

  function onChange(event, blurEvent, mountEvent) {
    let cronValue = event.target.value;
    if (cronValue === undefined || cronValue === '') {
      if (required === true && mountEvent === false) {
        setIsCronError(true);
        setCronMessage('Required');
      }
      else {
        setIsCronError(false);
        setCronMessage('');
        delete errors.cronError;
        handleChange(cronValue);
        return;
      }
    }

    const validationResult = validateCron(cronValue, mountEvent);
    const message = cronValue ? validationResult.validationMsg.length > 0 ? validationResult.validationMsg[0] : '' : '';
    const hasError = cronValue ? validationResult.hasError === true : false;
    if (hasError) {
      errors['cronError'] = message;
    } else {
      delete errors.cronError;
    }

    setIsCronError(hasError);
    setCronMessage(message);

    if (!blurEvent)
      handleChange(cronValue);
  }

  useEffect(() => onChange({target: {value: cronValue}}, true, true), []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id={selectId}
          name={fieldName}
          label={'Cron (UTC)'}
          value={cronValue}
          error={disabled ? null : isCronError}
          helperText={disabled ? ' ' : cronMessage ? '\u25B4 ' + cronMessage : ' '}
          FormHelperTextProps={{sx: {textAlign: 'right', lineHeight: 1, mx: 0, my: '0.125rem'}}}
          fullWidth
          onBlur={event => onChange(event, true, false)}
          onChange={event => onChange(event, false, false)}
          variant='outlined'
          required={required}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default DataStreamCron;