import React from 'react';
import {Circle, Check, Block} from '@mui/icons-material';
import {green, red} from '@mui/material/colors';
const minWidthDefault = 200;

const columns = [
  {
    field: 'id',
    type: 'number',
    headerName: 'ID',
    description: 'numeric device id',
    minWidth: 75
  },
  {
    field: 'owner_name',
    headerName: 'OWNER',
    description: 'owner of the device',
    minWidth: 400
  },
  {
    field: 'mac_address',
    headerName: 'MAC ADDRESS',
    description: 'device mac address',
    minWidth: minWidthDefault
  },
  {
    field: 'activation_code',
    headerName: 'ACTIVATION CODE',
    description: 'activation code for device',
    minWidth: 250
  },
  {
    field: 'api_key',
    headerName: 'API KEY',
    description: 'api gateway api key',
    minWidth: 200
  },
  {
    field: 'activation_attempts',
    type: 'number',
    headerName: 'ACTIVATION ATTEMPTS',
    description: 'number of attempts to activate',
    minWidth: 300
  },
  {
    field: 'active',
    headerName: 'ACTIVE',
    description: 'is the device active',
    minWidth: 150,
    renderCell: (params) => {
      if (params.value === 1) {
        return <Check style={{ color: green[500] }} />
      } else {
        return <Block style={{ color: red[500] }} />
      }
    }
  },
  {
    field: 'backend_url',
    headerName: 'BACKEND URL',
    description: 'configured backend url to make all requests',
    minWidth: minWidthDefault
  },
  {
    field: 'activation_date',
    headerName: 'ACTIVATION DATE',
    type: 'dateTime',
    valueGetter: ({value}) => value && new Date(value),
    description: 'date the device was activated',
    minWidth: 300
  },
  {
    field: 'health_timestamp',
    headerName: 'HEALTH',
    description: 'date time device last checked in',
    minWidth: 150,
    renderCell: (params) => {
      // TODO: Make this a gradient depending on how old the last health check is?
      if (params.value) {
        let now = new Date();
        let last_health_check = new Date(params.value);
        if (now - last_health_check < 300000) {
          return <Circle style={{ color: green[500] }} />
        }
      }
      return <Circle style={{ color: red[500]}} />
    }
  },
  {
    field: 'created_by',
    headerName: 'CREATED BY',
    description: 'user who created the device',
    minWidth: 175
  },
  {
    field: 'created_datetime',
    type: 'dateTime',
    valueGetter: ({value}) => value && new Date(value),
    headerName: 'CREATED DATE TIME',
    description: 'date time device was created',
    minWidth: 250
  },
  {
    field: 'updated_by',
    headerName: 'UPDATED BY',
    description: 'last user to update the device',
    minWidth: 175
  },
  {
    field: 'updated_datetime',
    type: 'dateTime',
    valueGetter: ({value}) => value && new Date(value),
    headerName: 'LAST UPDATED DATE TIME',
    description: 'date time the device was last updated',
    minWidth: 250
  }
];

export {columns};