import React from 'react';
import {FormControl, Select, MenuItem, Typography} from '@mui/material';
import {useHeaderFilter} from './useHeaderFilter';

const StatusSelect = (props) => {
  const {handleChange, value} = useHeaderFilter(props);

  return (
    <FormControl variant='standard' fullWidth>
      <Select
        value={value}
        onChange={handleChange}
      >
        <MenuItem value=''><em>Any</em></MenuItem>
        <MenuItem value='SUCCEEDED'><Typography sx={{color: 'green'}}>SUCCEEDED</Typography></MenuItem>
        <MenuItem value='RUNNING'><Typography sx={{color: 'blue'}}>RUNNING</Typography></MenuItem>
        <MenuItem value='FAILED'><Typography sx={{color: 'red'}}>FAILED</Typography></MenuItem>
      </Select>
    </FormControl>
  );
};

export default StatusSelect;