import React from 'react';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    description: 'Provider ID',
    width: 45
  },
  {
    field: 'name',
    headerName: 'Name',
    description: 'Name',
    minWidth: 200
  },
  {
    field: 'description',
    headerName: 'Description',
    description: 'Description',
    minWidth: 400
  },
  {
    field: 'has_hwm',
    headerName: 'Has HWM',
    description: 'Has high water mark',
    cellClassName: (params) => params.value === 'true' ? 'bool-cell-true' : 'bool-cell-false',
    width: 130
  },
  {
    field: 'hwm_format',
    headerName: 'HWM Format',
    description: 'High water mark format',
    minWidth: 210
  },
  {
    field: 'device_provider',
    headerName: 'Device',
    headerClassName: 'header',
    description: 'Is a PCAgent data feed',
    cellClassName: (params) => params.value === 'true' ? 'bool-cell-true' : 'bool-cell-false',
    width: 100
  },
  {
    field: 'v3',
    headerName: 'V3',
    headerClassName: 'header',
    description: 'Runs in version 3',
    cellClassName: (params) => params.value === 'true' ? 'bool-cell-true' : 'bool-cell-false',
    width: 100
  },
  {
    field: 'timezone_sensitive',
    headerName: 'Timezone Sensitive',
    headerClassName: 'run-params-header',
    description: 'For an API feed, whether schedule depends on timezone',
    cellClassName: (params) => params.value === 'true' ? 'bool-cell-true' : 'bool-cell-false',
    width: 170
  },
  {
    field: 'cron',
    headerName: 'Cron (UTC)',
    headerClassName: 'run-params-header',
    description: 'Cron schedule',
    minWidth: 130
  },
  {
    field: 'concurrency_limit',
    headerName: 'Concurrency',
    headerClassName: 'run-params-header',
    description: 'Max number of concurrent data extractions',
    width: 150
  },
  {
    field: 'backoff_interval',
    headerName: 'Backoff (sec)',
    headerClassName: 'run-params-header',
    description: 'Seconds to wait following API issues',
    width: 150
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    description: 'Created By',
    minWidth: 175
  },
  {
    field: 'created_datetime',
    headerName: 'Created Datetime',
    description: 'Created Datetime',
    minWidth: 225
  },
  {
    field: 'updated_by',
    headerName: 'Updated By',
    description: 'Updated By',
    minWidth: 175
  },
  {
    field: 'updated_datetime',
    headerName: 'Updated Datetime',
    description: 'Updated Datetime',
    minWidth: 225
  },
];

export {columns};