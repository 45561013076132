import dayjs from 'dayjs';
import {getGridStringOperators, getGridSingleSelectOperators, getGridDateOperators}
  from '@mui/x-data-grid-pro';
import {LAMBDAS} from '../utils';

export const columns = [
  {
    field: 'app_name',
    headerName: 'App Name',
    type: 'singleSelect',
    filterOperators: [getGridSingleSelectOperators().find(op => op.value === 'is')],
    valueOptions: LAMBDAS.map(lambda => lambda.name),
    minWidth: 150
  },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    type: 'dateTime',
    filterOperators: getGridDateOperators(true)
      .filter(op => ['after', 'onOrAfter', 'before', 'onOrBefore'].includes(op.value)),
    valueGetter: ({value}) => value && new Date(value),
    valueFormatter: ({value}) => value && dayjs(value).format('ddd M/D/YYYY, h:mm:ss.SSS A'),
    minWidth: 240
  },
  {
    field: 'severity',
    headerName: 'Severity',
    type: 'singleSelect',
    filterOperators: [getGridSingleSelectOperators().find(op => op.value === 'is')],
    valueOptions: ['INFO', 'WARNING', 'ERROR'],
    minWidth: 150
  },
  {
    field: 'message',
    headerName: 'Message',
    type: 'string',
    filterOperators: [getGridStringOperators().find(op => op.value === 'contains')],
    minWidth: 535
  }
];