import React from 'react';
import {Helmet} from 'react-helmet';
import {Box, Divider, Paper, Typography} from '@mui/material';
import PropTypes from 'prop-types';

/**
 * A Canvas object that handles the boilerplate for rendering components to the canvas.
 * @param appName the app name displayed in the header (e.g. nABLE or nTELL)
 * @param title the name of the current view, displayed in the header and the canvas page title.
 * @param children The content of the view.
 * @returns {JSX.Element}
 * @constructor
 */
const Canvas = ({appName, title, children, sx}) => {
  return (
    <Box>
      <Helmet>
        <title>{title} | {appName} </title>
      </Helmet>
      <Paper sx={{p: '1rem', flex: '1 1 auto', ...sx}}>
        <Typography variant='h1'>{title}</Typography>
        <Divider sx={{my: '1.5rem'}}/>
        {children}
      </Paper>
    </Box>
  );
};

Canvas.propTypes = {
  appName: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Canvas;