import React from 'react';
import {Button, Dialog, DialogTitle, Paper} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {columns} from './deviceLogColumns';

const UploadLogsDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      maxWidth='lg'
      fullWidth={true}
    >
      <DialogTitle>
        Upload History for Device
      </DialogTitle>
      <Paper sx={{height: '50vh', '& .disabled': {color: '#b2b29b'}}}>
        <DataGridPro
          sx={{height: '60%'}}
          columns={columns}
          rows={props.logs}
        />
      </Paper>
      <Button variant='contained' onClick={props.close}>
        Cancel
      </Button>
    </Dialog>
  );
};

export default UploadLogsDialog;