import React from 'react';
import {useTheme} from '@mui/material';
const logo = require('url:../../../static/images/ntell-logo.png');

const SidebarImage = () => {
  const theme = useTheme();

  const maxWidth = theme.components.MuiDrawer?.styleOverrides?.root?.width;

  // return (<img src={logo} alt='Logo' style={{maxWidth}} />);
  return (<></>);
};

export default SidebarImage;