const minWidthDefault = 200;

const columns = [
  {
    field: 'source',
    headerName: 'PROVIDER NAME',
    description: 'The name of the provider data is being uploaded for',
    minWidth: 250
  },
  {
    field: 'message',
    headerName: 'MESSAGE',
    description: 'Additional information about the upload event',
    minWidth: 300
  },

  {
    field: 'fileCount',
    headerName: 'FILE COUNT',
    description: 'Number of files uploaded',
    minWidth: minWidthDefault
  },
  {
    field: 'created_datetime',
    headerName: 'UPLOAD TIME',
    description: 'The date and time the upload occurred',
    minWidth: 250
  }
];

export {columns};