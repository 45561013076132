export const LAMBDAS = [
  {name: 'sfn_trigger', color: 'orange', isMainLambda: true},
  {name: 'ngest_extract', color: 'lightskyblue', isMainLambda: true},
  {name: 'ngest_extract_webscrape_v2', color: 'mediumturquoise', isMainLambda: true},
  {name: 'ngest_transform', color: 'darkgoldenrod', isMainLambda: true},
  {name: 'ngest_clean', color: 'darkcyan', isMainLambda: true},
  {name: 'ngest_stage', color: 'lightcoral', isMainLambda: true},
  {name: 'ngest_load', color: 'goldenrod', isMainLambda: true},
  {name: 'ngest_load_v3', color: 'lighsalmon', isMainLambda: true},
  {name: 'ms_ngest', color: 'indianred'},
  {name: 'ngest_api', color: 'mediumaquamarine'}
];
