const minWidthDefault = 200;

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        description: 'Owner ID',
        minWidth: 75
    },
    {
        field: 'name',
        headerName: 'Name',
        description: 'Name',
        minWidth: minWidthDefault
    },
    {
        field: 'description',
        headerName: 'Description',
        description: 'Description',
        minWidth: 400
    },
    {
        field: 'consumer_type_id',
        headerName: 'Description',
        description: 'Description',
        minWidth: 200
    },
    {
        field: 'consumer_type_name',
        headerName: 'Consumer Type Name',
        description: 'Consumer Type Name',
        minWidth: 300
    },
    {
        field: 'consumer_type_description',
        headerName: 'Consumer Type Description',
        description: 'Consumer Type Description',
        minWidth: minWidthDefault
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        description: 'Created By',
        minWidth: 175
    },
    {
        field: 'created_datetime',
        headerName: 'Created Datetime',
        description: 'Created Datetime',
        minWidth: 225
    },
    {
        field: 'updated_by',
        headerName: 'Updated By',
        description: 'Updated By',
        minWidth: 175
    },
    {
        field: 'updated_datetime',
        headerName: 'Updated Datetime',
        description: 'Updated Datetime',
        minWidth: 225
    }
];

export {columns};