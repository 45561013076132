import axios from 'axios';
import {fetchConfig} from '../util/util';

const HOST_URL = fetchConfig('hostUrl')

const getDevices = async () => {
  return (await axios.get(`${HOST_URL}/device-view`)).data;
};

const getDeviceLogs = async(mac_address) => {
  return (await axios.get(`${HOST_URL}/log/${mac_address}`)).data;
};

const createDevice = async (data) => {
  return (await axios.post(`${HOST_URL}/device`, data)).data;
};

const deleteDevice = async (deviceId) => {
  return (await axios.delete(`${HOST_URL}/device/admin/${deviceId}`)).data;
};

export default {getDevices, getDeviceLogs, createDevice, deleteDevice};