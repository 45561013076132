import React from 'react';
import {Box} from '@mui/material';
import Canvas from '../../navigation/canvas/Canvas';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Dashboard = ({}) => {
  const options = {
    chart: {
      type: 'spline'
    },
    title: {
      text: 'My chart'
    },
    series: [
      {
        data: [1, 2, 1, 4, 3, 6]
      }
    ],
    credits: {enabled: false}
  };

  return (
    <Canvas appName='nGEST' title='Dashboard'>
      {/*<Paper sx={{height: '77vh', '& .disabled': {color: "#b2b29b"}}}/>*/}
      <Box sx={{height: '500px'}}>
        <HighchartsReact
          highcharts={Highcharts}
          // constructorType='chart'
          options={options}
        />
      </Box>
    </Canvas>
  );
};

export default Dashboard;