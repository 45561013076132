import Amplify from 'aws-amplify';
import axios from 'axios';
import {LicenseInfo} from '@mui/x-license-pro';
import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {QueryClient, QueryClientProvider} from 'react-query';
import App from './App.jsx';
import IyoAuth from './auth/Auth';
import {store} from './store.js';
import {fetchConfig} from "./util/util";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000
    }
  }
});

axios.interceptors.request.use(async request => {
  const token = await IyoAuth.getIdToken();
  request.headers['Authorization'] = `Bearer ${token}`;
  return request;
}, error => {
  console.error('API error', error);
});

Amplify.configure(window.amplifyConfig);

const muiDataGridProLicenseKey = '61a1e8837c63b6e22e5027bdc6c4e9f0Tz01NjQ3MCxFPTE3MDMxODQ1MDg2ODYsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=';
LicenseInfo.setLicenseKey(muiDataGridProLicenseKey);

render (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
      <App/>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
