import React from 'react';
import {DesktopDateTimePicker} from '@mui/x-date-pickers-pro';
import {useHeaderFilter} from './useHeaderFilter';

const StartTimePicker = (props) => {
  const {handleChange, value} = useHeaderFilter(props);

  return (
    <DesktopDateTimePicker
      slotProps={{
        actionBar: {actions: ['accept']},
        tabs: {hidden: false},
        textField: {size: 'small'}
      }}
      disableFuture
      value={value}
      onAccept={handleChange}
      closeOnSelect={false}
    />
  );
};

export default StartTimePicker;