import React from 'react';
import {Alert, Snackbar} from '@mui/material';

const MessagePopup = ({displayText, duration, severity = 'success', open, setOpen = () => {},
                        onClose}) => {
  const hideDuration = duration ? duration : 60000;

  const handleClose = () => {
    setOpen(false);
    if (onClose)
      onClose();
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      autoHideDuration={hideDuration}
    >
      <Alert onClose={handleClose} severity={severity}>
        {displayText}
      </Alert>
    </Snackbar>
  );
};

export default MessagePopup;