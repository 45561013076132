export const isObject = (x) => {
  return x != null && typeof x === 'object';
};

export const isString = (x) => {
  const val = Object.prototype.toString.call(x) === "[object String]"
  return val;
};

export const fetchConfig = (param) => {
  let configValue;
  const fetchParam = hasParams(param);

  if (fetchParam) {
    configValue = fetchParam[param];
  } else {
    const error = `${param} config value is not valid!`;
    throw error;
  }
  return configValue;
};

export const hasParams = (paramArray, missingParamArray) => {
  let paramObj = {};
  let paramList;

  if(isString(paramArray)) {
    paramList = [paramArray];
  } else {
    paramList = [...paramArray];
  };

  let hasValues = true;

  for (let param of paramList) {
    const paramValue = window.amplifyConfig[param];
    if (!window.amplifyConfig || !paramValue) {
      if (missingParamArray) missingParamArray.push(param);
      hasValues = false;
    } else {
      paramObj[param] = paramValue;
    }
  }
  if(hasValues) {
    return paramObj;
  } else {
    return false;
  }
};

export const generateLabel = (str, fragment) => {
  if (!fragment) fragment = '';
  let myStr = '';
  let toUpper = true;

  for (let i = 0; i<str.length;i++) {
    if (str[i] === '_')  {
      myStr += fragment;
      toUpper = true;
    } else {
      if (toUpper) {
        myStr += str[i].toUpperCase();
        toUpper = false;
      } else {
        myStr += str[i];
      }
    }
  }
  return myStr;
};

export const buildQueryParams = (queryParams = {}) => {
  let additionalQueryStringParams = '';
  for (const key of Object.keys(queryParams)) {
    additionalQueryStringParams += additionalQueryStringParams === '' ? '?' : '&';
    additionalQueryStringParams += `${key}=${queryParams[key]}`;
  }
  return additionalQueryStringParams;
};
