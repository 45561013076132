import { Auth } from 'aws-amplify';
import jwtDecode from 'jwt-decode';

const login = async (user, pass) => Auth.signIn(user, pass);

const getUser = async () => {
  const userFetch = await Auth.currentAuthenticatedUser();
  return userFetch.username;
};

const getIdToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch(err) {
    console.error('Could not get ID token', err);
  }
};

const getUserId = async () => {
  let token = await getIdToken();
  let decodedToken = jwtDecode(token);
  return decodedToken.user_id;
};

const isLoggedIn = async () => !!(await getIdToken());

const logout = async () => await Auth.signOut();

export default {login, getUser, getIdToken, isLoggedIn, logout, getUserId};
