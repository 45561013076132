import React from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, Typography} from '@mui/material';

import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: '#d32f2f',
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1,
    textAlign: 'right',
    marginTop: '0.125rem',
    marginRight: 0,
    marginBottom: '0.125rem',
    marginLeft: 0,
    whiteSpace: 'pre-wrap'
  }
}));

/**
 * A MUI FormControl/Select component that works with Formik and has better spacing.
 * dataList must be an array of objects that have the attributes {name, value}.
 */
const IyoFormikDropDown = ({name, label, dataList, values, touched, errors, handleBlur, handleChange,
                             disabled = false, multiple = false,
                             required = false}) => {
  const myClasses = useStyles();

  return (
    <Box>
      <FormControl
        variant='outlined'
        fullWidth={true}
        error={Boolean(touched[name] && errors[name])}
        disabled={disabled}
        required={required}
      >
        <InputLabel id={name}>
          {label}
        </InputLabel>
        <Select
          label={label}
          name={name}
          value={values[name]}
          error={Boolean(touched[name] && errors[name])}
          onBlur={handleBlur}
          onChange={handleChange}
          multiple={multiple}
          required={required}
        >
          {dataList.map((rec) => (
            <MenuItem key={rec.id ?? rec.value} value={rec.value}>
              {rec.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography className={myClasses.errorText}>
        {Boolean(touched[name] && errors[name]) ? '\u25B4 ' + errors[name] : ' '}
      </Typography>
      {/*TODO Could replace w/ FormHelperText*/}
    </Box>
  )
};

export default IyoFormikDropDown;