import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import IyoAuth from '../../../auth/Auth';
import Handlers from './handlers';

const initializeColumns = (initialColumns, queryClient, setState) => {
  return initialColumns.concat(
    {
      field: 'provider_schema',
      headerName: 'Provider Schema',
      description: 'Provider Schema',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View Schema">
              <IconButton
                color="primary"
                onClick={(event) =>
                  Handlers.handleDialogOpen(
                    event,
                    'provider_schema',
                    params.row,
                    setState
                  )
                }
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
            &nbsp;
            <Tooltip title="View Properties">
              <IconButton
                color="primary"
                onClick={(event) =>
                  Handlers.handleDialogOpen(
                    event,
                    'provider_properties',
                    params.row,
                    setState
                  )
                }
              >
                <BuildCircleIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'data_stream_provider_properties',
      headerName: 'Datastream Properties',
      description: 'Datastream Properties',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View Properties">
              <IconButton
                color="primary"
                onClick={(event) =>
                  Handlers.handleDialogOpen(
                    event,
                    'data_stream_provider_properties',
                    params.row,
                    setState
                  )
                }
              >
                <BuildCircleIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      description: 'Actions',
      minWidth: 145,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                onClick={(event) =>
                  Handlers.handleEdit(event, params.row, queryClient, setState)
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    }
  );
};

const produceError = (error, setState) => {
  const message =
    error?.response?.data === undefined
      ? error.toString()
      : `${error.message}: ${JSON.stringify(error.response.data)}`;

  setState.setErrorOpen(false);
  setState.setErrDisplayText(message);
  setState.setErrorOpen(true);

  console.error(message);
};

const updateDataStream = async (values, state, putDataStream, schema) => {
  let body = {
    provider_properties: {},
  };

  for (let field in values) {
    if (values[field] !== state.rowEditing[field]) {
      const value = mapValueToSchema(field, values[field], schema);
      if (state.dataStreamFields.includes[field]) {
        body[field] = value;
      } else {
        body.provider_properties[field] = value;
      }
    }
  }

  body['provider_id'] = state.providerId;
  body['owner_id'] = state.ownerId;
  body['cron'] = state.cron;
  body['last_retrieval_date'] = state.lastRetrievalDate;
  body['updated_by'] = await IyoAuth.getUser();
  body['id'] = state.rowEditing.id;

  putDataStream.mutate(body);
};

const createDataStream = async (
  values,
  state,
  setState,
  dataStreamFields,
  postDataStream,
  schema
) => {
  let body = {
    provider_properties: {},
  };

  for (let [key, value] of Object.entries(values)) {
    value = mapValueToSchema(key, value, schema);
    if (dataStreamFields.includes(key)) {
      body[key] = value;
    } else {
      body.provider_properties[key] = value;
    }
  }

  body['provider_id'] = state.providerId;
  body['owner_id'] = state.ownerId;
  body['cron'] = state.cron;
  body['last_retrieval_date'] = state.lastRetrievalDate;

  postDataStream.mutate({
    ...body,
    created_by: await IyoAuth.getUser(),
    updated_by: await IyoAuth.getUser(),
  });
};

function mapValueToSchema(field, value, schema) {
  if (!value) {
    return undefined;
  }

  if (!schema) {
    return value;
  }

  const schemaInfo = schema.find(function (item) {
    return item.name === field;
  });
  switch (schemaInfo?.type.toLowerCase()) {
    case 'integer':
      value = Number.parseInt(value);
      break;

    case 'float':
      value = Number.parseFloat(value);
      break;

    case 'boolean':
      let boolVal = value.toString().trim().toLowerCase();
      switch (boolVal) {
        case 'true':
        case '1':
          value = true;
          break;
        case 'false':
        case '0':
          value = false;
          break;
        default:
          value = undefined;
          break;
      }
      break;

    case 'object':
      value = Object.create(value);
      break;
  }
  return value;
}

export default {
  initializeColumns,
  produceError,
  createDataStream,
  updateDataStream,
};
