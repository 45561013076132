export default {
  type: 'light',
  primary: {
    main: '#40a018',
    contrastText: '#F5F5F0',
  },
  secondary: {
    main: '#8a8a7d',
  },
  text: {
    primary: '#505046'
  },
  background: {
    default: '#f6f6f3',
  },
};