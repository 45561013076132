import React from 'react';
import {
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
  gridPreferencePanelStateSelector,
  GridPreferencePanelsValue
} from '@mui/x-data-grid-pro';
import {unstable_useId as useId} from '@mui/utils';
import Badge from '@mui/material/Badge';

// Stolen from https://github.com/mui/mui-x/issues/9464, cleaned up and fixed

const CustomFilterIcon = (props) => {
  const {counter = 0, field, onClick} = props;
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const preferencePanel = useGridSelector(
    apiRef,
    gridPreferencePanelStateSelector
  );
  const labelId = useId();
  const panelId = useId();

  const toggleFilter = React.useCallback(event => {
    event.preventDefault();
    event.stopPropagation();

    const {open, openedPanelValue} = gridPreferencePanelStateSelector(apiRef.current.state);

    if (open && openedPanelValue === GridPreferencePanelsValue.filters)
      apiRef.current.hideFilterPanel();
    else
      apiRef.current.showFilterPanel(field, panelId, labelId);

    if (onClick)
      onClick(apiRef.current.getColumnHeaderParams(field), event);
    },
    [apiRef, field, onClick, panelId, labelId]
  );

  const open = preferencePanel.open && preferencePanel.labelId === labelId;

  const iconButton = (
    <rootProps.slots.baseIconButton
      id={labelId}
      onClick={toggleFilter}
      color='default'
      aria-label={apiRef.current.getLocaleText('columnHeaderFiltersLabel')}
      size='small'
      tabIndex={-1}
      aria-haspopup='menu'
      aria-expanded={open}
      aria-controls={open ? panelId : undefined}
      {...rootProps.slotProps?.baseIconButton}
    >
      <rootProps.slots.columnFilteredIcon fontSize='small'/>
    </rootProps.slots.baseIconButton>
  );

  return (
    <rootProps.slots.baseTooltip
      title={apiRef.current.getLocaleText('columnHeaderFiltersTooltipActive')(counter)}
      enterDelay={1000}
      {...rootProps.slotProps?.baseTooltip}
    >
      <div>
        {counter > 1 && (
          <Badge badgeContent={counter} color='default'>
            {iconButton}
          </Badge>
        )}
        {counter <= 1 && iconButton}
      </div>
    </rootProps.slots.baseTooltip>
  );
};

export default CustomFilterIcon;