import React from 'react';

const Home = ({color}) => {
  const tempColor = (color === 'secondary') ? '#505046' : '#40a018';

  return (<svg width='29' height='26' viewBox='0 0 29 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d={'M28.7332 11.9976L25.7783 9.36558V2.43648C25.7783 2.22099 25.6934 2.01433 25.5423 1.86196C25.3913' +
          ' 1.70958 25.1864 1.62398 24.9727 1.62398H21.7505C21.5369 1.62398 21.332 1.70958 21.1809 1.86196C21.0298' +
          ' 2.01433 20.9449 2.22099 20.9449 2.43648V5.06136L15.8473 0.522536C15.4785 0.186213 14.999 0 14.5018' +
          ' 0C14.0045 0 13.5251 0.186213 13.1562 0.522536L0.267341 11.9991C0.188609 12.0704 0.124577 12.1567' +
          ' 0.078903 12.2529C0.0332295 12.3492 0.00680944 12.4536 0.00115213 12.5602C-0.00450517 12.6668' +
          ' 0.0107111 12.7734 0.0459318 12.8741C0.0811524 12.9747 0.135687 13.0674 0.206421 13.1468L1.28435' +
          ' 14.3548C1.35505 14.4343 1.44057 14.4988 1.53602 14.5449C1.63148 14.591 1.73499 14.6176 1.84066' +
          ' 14.6233C1.94632 14.629 2.05207 14.6137 2.15185 14.5782C2.25163 14.5426 2.3435 14.4876 2.4222' +
          ' 14.4163L13.9678 4.14425C14.115 4.0133 14.3045 3.94104 14.5008 3.94104C14.697 3.94104 14.8865' +
          ' 4.0133 15.0337 4.14425L26.5793 14.4158C26.658 14.4871 26.7499 14.5421 26.8497 14.5776C26.9494' +
          ' 14.6132 27.0552 14.6285 27.1608 14.6228C27.2665 14.6171 27.37 14.5905 27.4655 14.5444C27.5609' +
          ' 14.4983 27.6465 14.4337 27.7171 14.3543L28.7946 13.1447C28.9372 12.9844 29.0109 12.7734 28.9994' +
          ' 12.5583C28.9878 12.3432 28.8921 12.1415 28.7332 11.9976ZM14.5005 5.83882L3.49812 15.6249C3.41656' +
          ' 15.699 3.31234 15.7336 3.22272 15.795V25.1865C3.22272 25.402 3.30759 25.6086 3.45866 25.761C3.60974' +
          ' 25.9134 3.81463 25.999 4.02828 25.999H10.4727C10.6864 25.999 10.8913 25.9134 11.0423 25.761C11.1934' +
          ' 25.6086 11.2783 25.402 11.2783 25.1865V18.6865C11.2783 18.471 11.3631 18.2643 11.5142 18.112C11.6653' +
          ' 17.9596 11.8702 17.874 12.0838 17.874H16.9172C17.1308 17.874 17.3357 17.9596 17.4868 18.112C17.6379' +
          ' 18.2643 17.7227 18.471 17.7227 18.6865V25.1865C17.7227 25.402 17.8076 25.6086 17.9587 25.761C18.1097' +
          ' 25.9134 18.3146 25.999 18.5283 25.999H24.9727C25.1864 25.999 25.3913 25.9134 25.5423 25.761C25.6934' +
          ' 25.6086 25.7783 25.402 25.7783 25.1865V15.797C25.6927 15.7381 25.591 15.7046 25.5135 15.6345L14.5005' +
          ' 5.83882Z'}
        fill={tempColor}/>
    </svg>
  );
}
export default Home;