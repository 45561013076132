import React from 'react';

const minWidthDefault = 200;

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        description: 'Consumer Type ID',
        minWidth: 75
    },
    {
        field: 'name',
        headerName: 'Name',
        description: 'Name',
        minWidth: minWidthDefault
    },
    {
        field: 'description',
        headerName: 'Description',
        description: 'Description',
        minWidth: 400
    }
];

export {columns};