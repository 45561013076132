import 'typeface-montserrat';

export default {
  fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 800,
  h1: {
    fontWeight: 'medium',
    fontSize: 32,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 'bold',
    fontSize: 29,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontSize: 24,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontSize: 20,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontSize: 16,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontSize: 24,
    letterSpacing: '-0.05px'
  },
};
