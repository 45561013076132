import React from 'react';
import {FormControl, Select, MenuItem} from '@mui/material';
import {LAMBDAS} from '../utils';
import {useHeaderFilter} from './useHeaderFilter';

const LastLambdaSelect = (props) => {
  const {handleChange, value} = useHeaderFilter(props);

  return (
    <FormControl variant='standard' fullWidth>
      <Select
        value={value}
        onChange={handleChange}
      >
        {[
            <MenuItem key='' value=''><em>Any</em></MenuItem>,
            ...(LAMBDAS.filter(lambda => lambda.isMainLambda === true).map(lambda => (
              <MenuItem key={lambda.name} value={lambda.name}>{lambda.name}</MenuItem>
            )) ?? [])
        ]}
      </Select>
    </FormControl>
  );
};

export default LastLambdaSelect;