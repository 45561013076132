import axios from 'axios';
import {fetchConfig} from '../util/util';

const HOST_URL = fetchConfig('hostUrl')

const getOneById = async (providerId) => {
  return (await axios.get(`${HOST_URL}/provider/${providerId}`)).data;
};

const getAll = async () => {
  return (await axios.get(`${HOST_URL}/provider`)).data;
};

const post = async (data) => {
  return (await axios.post(`${HOST_URL}/provider`, data)).data;
};

const put = async (id, data) => {
  return (await axios.put(`${HOST_URL}/provider/${id}`, data)).data;
};

export default {getOneById, getAll, post, put};