import axios from 'axios';
import {fetchConfig} from '../util/util';

const executeQuery = async (queryName, queryParams) => {
  const additionalQueryStringParams = buildQueryParams(queryParams);
  const url = `${fetchConfig('nableHostUrl')}/rockset/${queryName}${additionalQueryStringParams}`;
  const response = await (await axios.get(url)).data;
  return response && response.length > 0 ? response : [];
};

const buildQueryParams = (queryParams = {}) => {
  let additionalQueryStringParams = '';
  for (const key of Object.keys(queryParams)) {
    additionalQueryStringParams += additionalQueryStringParams === '' ? '?' : '&';
    additionalQueryStringParams += `${key}=${queryParams[key]}`;
  }
  return additionalQueryStringParams;
};

export default {executeQuery};
