import {useCallback, useMemo} from 'react';
import {gridFilterModelSelector, useGridApiContext, useGridSelector} from '@mui/x-data-grid-pro';
import {columns} from '../columns';

export const useHeaderFilter = (props) => {
  const {colDef} = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = useMemo(
    () => filterModel.items?.filter(({field}) => field === colDef.field),
    [colDef.field, filterModel.items],
  );

  const handleChange = useCallback(event => {
      const value = event.target ? event.target.value : event;

      if (!value) {
        if (currentFieldFilters[0])
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
        return;
      }

      apiRef.current.upsertFilterItem({...currentFieldFilters[0], value: value});
    },
    [apiRef, colDef.field, currentFieldFilters]
  );

  const value = currentFieldFilters[0]?.value ??
    (columns.find(col => col.field === colDef.field).type === 'datetime' ? null : '');

  return {
    handleChange,
    value
  };
};