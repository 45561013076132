import React from 'react';
import SidebarAction from './SidebarAction';
import {useHistory} from 'react-router-dom';

const SidebarLink = ({link, postAction, label, icon, itemSx, iconSx, textSx}) => {
	const history = useHistory();

	const redirect = () => history.push(link);
	const fn = postAction ? async () => {
		redirect();
		await postAction();
	} : redirect;

	return (
		<SidebarAction label={label}
									 icon={icon}
									 actionCallback={fn}
									 isActive={window.location.pathname.includes(link)}
									 itemSx={itemSx || {}}
									 textSx={textSx || {}}
									 iconSx={iconSx || {}}
		/>
	);
};

export default SidebarLink;