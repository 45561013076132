import React from 'react';
import {Box, Button, IconButton, Tooltip} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const TableToolbar = ({ editHandler, onRefresh }) => (
  <Box>
    <Box
      sx={{
        mb: '1rem',
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      {onRefresh && (
        <Tooltip title='Refresh'>
          <IconButton color='primary' onClick={onRefresh}>
            <RefreshIcon/>
          </IconButton>
        </Tooltip>
      )}
      {editHandler && (
        <Button
          color='primary'
          variant='contained'
          onClick={editHandler}
        >
          Create
        </Button>
      )}
    </Box>
  </Box>
);

export default TableToolbar;
