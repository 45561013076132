import {TextField} from '@mui/material';

/**
 * A text field with a better helper text and spacing
 */
const IyoFormikTextField = ({name, label, values, touched, errors, handleBlur, handleChange,
                            required = false, numeric = false,
                            disabled = false}) => {
  return (
    <TextField
      name={name}
      label={label}
      value={values[name]}
      error={Boolean(touched[name] && errors[name])}
      helperText={Boolean(touched[name] && errors[name]) ? '\u25B4 ' + errors[name] : ' '}
      FormHelperTextProps={{sx: {textAlign: 'right', lineHeight: 1, mx: 0, my: '0.125rem'}}}
      fullWidth
      onBlur={handleBlur}
      onChange={handleChange}
      variant='outlined'
      inputProps={numeric ? {type: 'number'} : {}}
      required={required}
      disabled={disabled}
    />
  );
};

export default IyoFormikTextField;