import React, {useEffect, useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {Box, IconButton, LinearProgress, Link, Paper, Tooltip} from '@mui/material';
import {Refresh} from '@mui/icons-material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration.js';
import Canvas from '../../navigation/canvas/Canvas';
import DataFlowDetailsDialog from './data-flow-details-dialog/DataFlowDetailsDialog';
import ErrorPopup from '../../common/ErrorPopup';
import DataFlowService from '../../../apis/DataFlowService';
import {columns} from './columns';

dayjs.extend(duration);

const DataFlowDetails = ({}) => {
  // ####################[ Setup and Initialization ]####################
  const queryClient = useQueryClient();

  const initializeColumns = (initialColumns) => {
    initialColumns.find(col => col.field === 'execution_id').renderCell = (params) => (
      <Link component='button' onClick={(event) => handleDetails(event, params.row)} variant='body2'>
        {params.value}
      </Link>
    );

    return initialColumns;
  };

  const initialFilters = {items: [
      {id: 1, field: 'status', operator: 'is'},
      {id: 2, field: 'provider_name', operator: 'is'},
      {id: 3, field: 'owner_name', operator: 'is'},
      {id: 4, field: 'last_lambda_function', operator: 'is'},
      {id: 5, field: 'start_time', operator: '>', value: dayjs().subtract(1, 'day')}
    ]
  };

  // ####################[ State Definitions ]####################
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [errDisplayText, setErrDisplayText] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [executionId, setExecutionId] = useState(false);
  const [filterModel, setFilterModel] = useState(initialFilters);
  const [paginationModel, setPaginationModel] = useState({pageSize: 100, page: 0});
  const [rowCount, setRowCount] = useState(0);
  const [rowEditing, setRowEditing] = useState([]);
  const [sortModel, setSortModel] = useState([{field: 'start_time', sort: 'desc'}]);

  // ####################[ Query Definitions ]####################
  const getExecutions = useQuery(['getExecutions', filterModel, paginationModel, sortModel],
    async () => {

      const queryParams = Object.assign(
        {},
        ...filterModel.items.filter(filter => !!filter.value).map(filter => ({[filter.field]: filter.value})),
        ...sortModel.map(sort => ({[`sort_${sort.field}`]: sort.sort}))
      );
      queryParams.start_time = queryParams.start_time.toISOString();
      queryParams.page_num = paginationModel.page + 1;
      queryParams.page_size = paginationModel.pageSize;

      const response = await DataFlowService.getExecutions(queryParams);

      const count = response.count;

      const results = response.results
        .map(execution => {
          // Calculate duration and assign the id attribute
          const startTime = dayjs(execution.start_time);

          let dur;
          if (execution.status === 'SUCCEEDED' || execution.status === 'FAILED') {
            const endTime = dayjs(execution.end_time);
            dur = endTime.diff(startTime);
          }
          else if (execution.status === 'RUNNING') {
            dur = dayjs().diff(startTime);
          }

          return {
            id: execution.execution_id,
            duration: dur ? dayjs.duration(dur).format('HH:mm:ss') : null,
            ...execution
          };
        })

      return {
        count: count,
        results: results
      };
    },
    {
      onError: (error) => handleError(error),
      onSuccess: (data) => {
        if (rowEditing)
          setRowEditing(data.results.find(execution => execution.id === rowEditing.id));
        if (rowCount !== data.count)
          setRowCount(data.count);
      },
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity
    }
  );

  // ####################[ Event Handlers ]####################
  const handleDetails = (event, row) => {
    setRowEditing(row);
    setExecutionId(row.id);
    setDetailsOpen(true);
  };

  const handleError = (error) => {
    setErrorOpen(false);
    setErrDisplayText(`${error.message}: ` +
      `${error.response ? JSON.stringify(error.response?.data) : error.toString().slice(0, 200)}`);
    setErrorOpen(true);

    console.error(`${error.message}: ${JSON.stringify(error.response?.data || error)}`);
  };

  const handleRefresh = async () => {
    await queryClient.invalidateQueries('getExecutions');
  };

  // ####################[ The Component ]####################
  return (
    <Box>
      <Canvas appName='nGEST' title='Data Flow Details'>
        <Box sx={{display: 'flex'}}>
          <Box sx={{width: '100%', display: 'inline-flex', justifyContent: 'end'}}>
            <Tooltip title='Refresh'>
              <IconButton sx={{py: 0, pl: 0, height: '3rem'}} color='primary' onClick={handleRefresh}>
                <Refresh fontSize='large'/>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Paper sx={{
          height: '75vh',
          '& .SUCCEEDED': {color: 'green'},
          '& .FAILED': {color: 'red'},
          '& .RUNNING': {color: 'blue'}
        }}>
          <DataGridPro
            columns={initializeColumns(columns)}
            rows={getExecutions?.data?.results || []}
            slots={{loadingOverlay: LinearProgress}}
            loading={getExecutions.isLoading}
            density='compact'
            getCellClassName={(params) => {
              if (params.field === 'status') {
                return params.value;
              }
            }}

            filterMode='server'
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            unstable_headerFilters
            disableColumnFilter
            disableColumnMenu

            pagination
            rowCount={rowCount}
            paginationMode='server'
            pageSizeOptions={[25, 50, 100, 500, 1000]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}

            sortingMode='server'
            sortModel={sortModel}
            onSortModelChange={setSortModel}
          />
        </Paper>
      </Canvas>
      <DataFlowDetailsDialog
        open={detailsOpen}
        setOpen={setDetailsOpen}
        rowEditing={rowEditing}
        handleError={handleError}
        executionId={executionId}
      />
      <ErrorPopup
        open={errorOpen}
        setOpen={setErrorOpen}
        displayText={errDisplayText}
      />
    </Box>
  );
};

export default DataFlowDetails;