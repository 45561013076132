import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {AppBar, Box, IconButton, Tooltip,} from '@mui/material';
import {HelpOutline, NotificationsNoneOutlined, PersonOutline, Search} from '@mui/icons-material';
import NavigationTopBarContent from './NavigationTopBarContent';
import {AccountInfoMenu, HelpMenu, NotificationsMenu, SearchMenu} from './menus';
import IyoAuth from '../../../auth/Auth';

const tooltipSx = {m: '1rem'};

const buttonColor = 'secondary';

/**
 * Creates the top bar on an iYOTAH application.
 * @param dropDownSx (optional) Material-UI style object for the business drop-down
 * @param buttonSx (optional) Material-UI style object for the menu buttons
 * @returns {JSX.Element} a React component
 * @constructor
 */
const NavigationTopBar = ({dropDownSx, buttonSx}) => {
  const history = useHistory();
  const [anchor, setAnchor] = useState(null);
  const [accountInfoOpen, setAccountInfoOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const handleAccountInfoClose = () => {
    setAccountInfoOpen(false);
    setAnchor(null);
  };

  const handleHelpClose = () => {
    setHelpOpen(false);
    setAnchor(null);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
    setAnchor(null);
  };

  const handleNotificationsClose = () => {
    setNotificationsOpen(false);
    setAnchor(null);
  };

  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer,
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Box sx={{flexBasis: (theme) => theme.components.MuiDrawer?.styleOverrides?.root?.width}} />
      <NavigationTopBarContent dropDownSx={dropDownSx}
                               logoutCallback={() => IyoAuth.logout().then(_ => history.push('/login'))}>
        <Tooltip sx={tooltipSx} title='Search Features'>
          <IconButton onClick={() => setSearchOpen(true)} size='small'>
            <Search color={buttonColor} sx={buttonSx}/>
          </IconButton>
        </Tooltip>
        <Tooltip sx={tooltipSx} title='Help'>
          <IconButton onClick={() => setHelpOpen(true)} size='small'>
            <HelpOutline color={buttonColor} sx={buttonSx}/>
          </IconButton>
        </Tooltip>
        <Tooltip sx={tooltipSx} title='Account settings'>
          <IconButton onClick={() => setAccountInfoOpen(true)} size='small'>
            <PersonOutline color={buttonColor} sx={buttonSx}/>
          </IconButton>
        </Tooltip>
        <Tooltip sx={tooltipSx} title='Notifications'>
          <IconButton onClick={() => setNotificationsOpen(true)} size='small'>
            <NotificationsNoneOutlined color={buttonColor} sx={buttonSx}/>
          </IconButton>
        </Tooltip>
      </NavigationTopBarContent>
      <AccountInfoMenu open={accountInfoOpen} anchorElement={anchor} onClose={handleAccountInfoClose} />
      <HelpMenu open={helpOpen} anchor={anchor} onClose={handleHelpClose} />
      <SearchMenu open={searchOpen} anchor={anchor} onClose={handleSearchClose} />
      <NotificationsMenu open={notificationsOpen} anchor={anchor} onClose={handleNotificationsClose} />
    </AppBar>
  );
};

export default NavigationTopBar;