import axios from "axios";
import { buildQueryParams, fetchConfig } from "../util/util";
import Auth from "../auth/Auth";

const HOST_URL = fetchConfig("hostUrl");

const get = async (owner_id) => {
  const queryParams = buildQueryParams({ owner_id });
  return (await axios.get(`${HOST_URL}/owner-consumer-default${queryParams}`))
    .data;
};

const post = async (owner_id, consumer_id) => {
  const currentUser = await Auth.getUser();
  const data = {
    owner_id,
    consumer_id,
    created_by: currentUser,
    updated_by: currentUser,
  };
  return (await axios.post(`${HOST_URL}/owner-consumer-default`, data)).data;
};

const del = async (owner_id, consumer_id) => {
  const queryParams = buildQueryParams({ owner_id, consumer_id });
  return (
    await axios.delete(`${HOST_URL}/owner-consumer-default${queryParams}`)
  ).data;
};

export default { get, del, post };
