import React from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select} from '@mui/material';

import {makeStyles} from '@mui/styles';
import {generateLabel} from '../../util/util';

const useStyles = makeStyles((theme) => ({
  hasError: {
    '& label': {
      color: '#d32f2f',
    }
  },
  errorText: {
    color: '#d32f2f',
    fontFamily: 'Roboto, Helvetica, Arial,sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
    marginTop: '3px',
    marginRight: '14px',
    marginBottom: 0,
    marginLeft: '14px'
  }
}));

/***
 *
 * @param fieldName A custom name for the IyoDropDown.  This should be a unique identifier.
 * @param dataList An array of data entries.  Each entry must contain attributes {id, name}.
 * @param value The field to which selections get assigned.  See Material-UI's Select's value field for more info.
 * @param errors An array of errors.  Designed to work with Formik.
 * @param formTouched a Boolean value stating whether this field has been touched.
 * @param handleChange Handles value change.
 * @returns {JSX.Element}
 * @constructor
 */
const IyoFormikDropDownLegacy = ({fieldName, dataList, value, errors, touched, handleChange, multiple = false}) => {
  const myClasses = useStyles();

  const selectId = fieldName.replace('_','-');
  const labelId = selectId + '-label';
  const inputLabel = generateLabel(fieldName, ' ');
  const labelId2 = generateLabel(fieldName, '');

  return (
    <Box>
      <FormControl variant='outlined' fullWidth={true} className={Boolean(errors[fieldName]) ? myClasses.hasError : ''}>
        <InputLabel id={labelId}>
          {inputLabel}
        </InputLabel>
        <Select
          labelId={labelId2}
          id={selectId}
          value={value}
          onChange={(event, data) => handleChange(data.props)}
          label={inputLabel}
          error={touched && Boolean(errors[fieldName])}
          multiple={multiple}
        >
          {dataList.map((rec) => (
            <MenuItem key={rec.id} value={rec.id}>
              {rec.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box className={myClasses.errorText}>{errors[fieldName]}</Box>
    </Box>
  )
};

export default IyoFormikDropDownLegacy;