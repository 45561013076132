import React from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom';
import {PulseLoader} from 'react-spinners';
import {css} from '@emotion/react';
import {Divider, ListItem, ListItemIcon, Menu, MenuItem} from '@mui/material';
import {Logout, Settings} from '@mui/icons-material';
import IyoAuth from '../../../../auth/Auth';

const AccountInfoMenu = ({open, anchorElement, onClose}) => {
  const history = useHistory();

  const pulseLoaderCss = css`
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  `;

  const username = useQuery('username', IyoAuth.getUser);

  return (
    <Menu
      anchorEl={anchorElement}
      open={open}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <ListItem>
        {username.data || <PulseLoader color='green' css={pulseLoaderCss} /> }
      </ListItem>
      <Divider />
      <MenuItem>
        <ListItemIcon>
          <Settings fontSize='small' />
        </ListItemIcon>
        Settings
      </MenuItem>
      <MenuItem onClick={() => IyoAuth.logout().then(success => history.push('/login'))}>
        <ListItemIcon>
          <Logout fontSize='small' />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
};

export default AccountInfoMenu;