import React, {useState} from 'react';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import {Box, IconButton, LinearProgress, Paper, Tooltip} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import Canvas from '../../navigation/canvas/Canvas';
import ConsumerEditDialog from './ConsumerEditDialog';
import ErrorPopup from '../../common/ErrorPopup';
import ScrollableDialog from '../../common/ScrollableDialog';
import TableToolbar from '../../common/TableToolbar';
import ConsumerService from '../../../apis/ConsumerService';
import IyoAuth from '../../../auth/Auth';
import {columns} from './columns';

const Consumers = ({}) => {
  // ####################[ Setup and Initialization ]####################
  const consumer_fields = ['id', 'name', 'description', 'consumer_type_id', 'created_by', 'updated_by'];

  const queryClient = useQueryClient();

  const initializeColumns = (initialColumns) => {
    return initialColumns.concat({
      field: 'actions',
      headerName: 'Actions',
      description: 'Actions',
      minWidth: 175,
      renderCell: (params) => {
        return (
          <Box>
            <Tooltip title='Edit'>
              <IconButton color='primary' onClick={(event) => handleEdit(event, params.row)}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
            &nbsp;
            {
              params.row.disabled ?
                  <Tooltip title='Enable'>
                    <IconButton color='secondary' onClick={(event) => handleDisableSubmit(event, params.row)}>
                      <VisibilityOffIcon/>
                    </IconButton>
                  </Tooltip> :
                  <Tooltip title='Disable'>
                    <IconButton color='primary' onClick={(event) => handleDisableSubmit(event, params.row)}>
                      <VisibilityIcon/>
                    </IconButton>
                  </Tooltip>
            }
            <Tooltip title='View Properties'>
              <IconButton color='primary' onClick={(event) => handleDialogOpen(event, params.row)}>
                <InfoIcon/>
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    });
  };

  // ####################[ State Definitions ]####################
  const [cols, setCols] = useState(initializeColumns(columns));
  const [scrollableDialogOpen, setScrollableDialogOpen] = useState(false);
  const [scrollableDialogData, setScrollableDialogData] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [rowEditing, setRowEditing] = useState([]);
  const [errDisplayText, setErrDisplayText] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [editMode, setEditMode] = useState('');
  const [consumerTypeId, setConsumerTypeId] = useState(null);

  // ####################[ Query Definitions ]####################
  const getConsumers = useQuery('getConsumers', async () => {
      return await ConsumerService.getAll();
    },
    {
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity
    }
  );

  const postConsumer = useMutation('postConsumer', async (data) => {
    return await ConsumerService.post(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getConsumers');
    },
    onError: (error) => {
      handleError(error);
    }
  });

  const putConsumer = useMutation('putConsumer', async (data) => {
    return await ConsumerService.put(rowEditing.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getConsumers');
    },
    onError: (error) => {
      handleError(error);
    }
  });

  // ####################[ Event Handlers ]####################
  const handleDialogOpen = async (event, row) => {
    setScrollableDialogData(JSON.stringify(row.properties, undefined, 2));
    setScrollableDialogOpen(true);
  };

  const handleDialogClose = () => {
    setScrollableDialogOpen(false);
  };

  const handleCreate = async (event, row) => {
    setEditMode('create');
    setRowEditing([]);
    setConsumerTypeId(null);
    setEditOpen(true);
  };

  const handleCreateSubmit = async (values) => {
    let body = {
      properties: {}
    };

    for (const [key, value] of Object.entries(values)) {

      if (consumer_fields.includes(key)) {
        body[key] = value;
      } else {
        body.properties[key] = value;
      }
    }

    postConsumer.mutate({...body, created_by: await IyoAuth.getUser(), updated_by: await IyoAuth.getUser()});

    setEditOpen(false);
  };

  const handleDisableSubmit = async (event, row) => {
    console.info(`${row.disabled ? 'Enabling' : 'Disabling'} consumer ${row.id}`);
    setRowEditing(row);

    const body = {id: row.id, disabled: row.disabled ? 0 : 1, updated_by: await IyoAuth.getUser()};

    putConsumer.mutate(body);
  };

  const handleEdit = async (event, row) => {
    setEditMode('edit');
    setRowEditing(row);
    setConsumerTypeId(row.consumer_type_id);
    setEditOpen(true);
  };

  const handleEditSubmit = async (values) => {
    let body = {
      properties: {}
    };

    for (let field in values) {
      if (values[field] !== rowEditing[field]) {
        if (consumer_fields.includes(field)) {
          body[field] = values[field];
        } else {
          body.properties[field] = values[field];
        }
      }
    }

    body['consumer_type_id'] = values.consumer_type_id;
    body['updated_by'] = await IyoAuth.getUser();
    body['id'] = rowEditing.id;

    putConsumer.mutate(body);

    setEditOpen(false);
  };

  const handleError = (error) => {
    setErrorOpen(false);
    setErrDisplayText(`${error.message}: ${JSON.stringify(error.response.data)}`);
    setErrorOpen(true);

    console.error(`${error.message}: ${JSON.stringify(error.response.data)}`);
  };

  const refreshTable = async () => {
    console.info('Refreshing table');
    await queryClient.invalidateQueries('getConsumers');
  };

  // ####################[ The Component ]####################
  return (
    <Box>
      <Canvas appName='nGEST' title='Consumers'>
        <TableToolbar editHandler={handleCreate} onRefresh={refreshTable}/>
        <Paper sx={{ height: '77vh', '& .disabled': {color: '#b2b29b'} }}>
          <DataGridPro
            columns={cols}
            rows={getConsumers.data || []}
            getRowClassName={(params) => params.row.disabled ? 'disabled' : ''}
            slots={{loadingOverlay: LinearProgress, toolbar: GridToolbar}}
            loading={getConsumers.isLoading}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  consumer_type_id: false,
                  consumer_type_description: false,
                  created_by: false,
                  created_datetime: false,
                  updated_by: false,
                  updated_datetime: false
                }
              }
            }}
            density='compact'
          />
        </Paper>
      </Canvas>
      <ConsumerEditDialog
        type='edit'
        open={editOpen}
        setOpen={setEditOpen}
        rowEditing={rowEditing}
        mode={editMode}
        consumerTypeId={consumerTypeId}
        setConsumerTypeId={setConsumerTypeId}
        handleEditSubmit={handleEditSubmit}
        handleCreateSubmit={handleCreateSubmit}
        handleError={handleError}
      />
      <ScrollableDialog
        open={scrollableDialogOpen}
        setOpen={setScrollableDialogOpen}
        close={handleDialogClose}
        content={scrollableDialogData}
        scroll={'paper'}
      />
      <ErrorPopup
        open={errorOpen}
        setOpen={setErrorOpen}
        displayText={errDisplayText}
      />
    </Box>
  );
};

export default Consumers;