import React, {useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {Box, Button, Dialog, DialogTitle, Grid, IconButton, LinearProgress, Paper, Popover, Tooltip,
  Typography} from '@mui/material';
import {Refresh} from '@mui/icons-material';
import {DataGridPro, GridToolbarFilterButton, useGridApiRef,
  getGridStringOperators, getGridSingleSelectOperators, getGridDateOperators} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration.js';
import DataFlowService from '../../../../apis/DataFlowService';
import {columns} from './columns';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {atomOneLight} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CustomFilterIcon from '../../../common/CustomFilterIcon';
import {LAMBDAS} from '../utils';

dayjs.extend(duration);

const DataFlowDetailsDialog = ({open, setOpen, rowEditing, handleError}) => {
  // ####################[ Setup and Initialization ]####################
  const queryClient = useQueryClient();
  const apiRef = useGridApiRef();

  // ####################[ State Definitions ]####################
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState(null);
  const [popoverText, setPopoverText] = useState(null);
  const [popoverType, setPopoverType] = useState(null);

  // ####################[ Query Definitions ]####################
  const getLogs = useQuery(['getLogs', rowEditing?.id], async () => {
    const data = await DataFlowService.getLogs(rowEditing.id);

    return data
      .map(log => ({id: log._id, ...log}))
  },
    {
      onError: (error) => handleError(error),
      enabled: open,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity
    }
  );

  // ####################[ Event Handlers ]####################
  const handleCellClick = (params, event) => {
    if (params.field === 'message') {
      let text;
      try {
        text = JSON.stringify(JSON.parse(params.value), null, 2);
        setPopoverType('json');
      }
      catch (err) {
        text = params.value;
        setPopoverType(null);
      }
      setPopoverText(text);
      setPopoverPosition({left: event.clientX, top: event.clientY});
      setPopoverOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefresh = async () => {
    await queryClient.invalidateQueries('getLogs');
  };

  // ####################[ Subcomponents ]####################
  const UpperText = ({}) => {
    const startTime = dayjs(rowEditing.start_time);
    const endTime = rowEditing.end_time ? dayjs(rowEditing.end_time) : null;

    const statusColor = rowEditing.status === 'SUCCEEDED' ?
      'green' :
      rowEditing.status === 'FAILED' ?
        'red' :
        rowEditing.status === 'RUNNING' ?
          'blue' :
          null;

    return (
      <Grid container>
        <Grid item xs={2}><Typography sx={{fontWeight: 500}}>Status</Typography></Grid>
        <Grid item xs={10}><Typography sx={{color: statusColor}}>{rowEditing.status}</Typography></Grid>

        <Grid item xs={2}><Typography sx={{fontWeight: 500}}>Provider</Typography></Grid>
        <Grid item xs={10}><Typography>{rowEditing.provider_name}</Typography></Grid>

        <Grid item xs={2}><Typography sx={{fontWeight: 500}}>Owner</Typography></Grid>
        <Grid item xs={10}><Typography>{rowEditing.owner_name}</Typography></Grid>

        <Grid item xs={2}><Typography sx={{fontWeight: 500}}>Start Time</Typography></Grid>
        <Grid item xs={10}><Typography>
          {startTime.format('ddd M/D/YYYY, h:mm:ss A')}
        </Typography></Grid>

        <Grid item xs={2}><Typography sx={{fontWeight: 500}}>End Time</Typography></Grid>
        <Grid item xs={10}><Typography>
          {rowEditing.end_time ? endTime.format('ddd M/D/YYYY, h:mm:ss A') : null}
        </Typography></Grid>

        <Grid item xs={2}><Typography sx={{fontWeight: 500}}>Duration</Typography></Grid>
        <Grid item xs={10}><Typography>{rowEditing.duration}</Typography></Grid>
      </Grid>
    );
  };

  const CustomToolbar = ({}) => (
    <Box sx={{alignSelf: 'start'}}>
      <GridToolbarFilterButton/>
    </Box>
  );

  // ####################[ The Component ]####################
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <DialogTitle>
        {`Data Flow ${rowEditing?.id}`}
      </DialogTitle>
      <Box sx={{display: 'flex', mx: '2%'}}>
        <Box sx={{width: '50%', display: 'inline-flex', justifyContent: 'start'}}>
          <UpperText/>
        </Box>
        <Box sx={{width: '50%', display: 'inline-flex', alignItems: 'end', justifyContent: 'end'}}>
          <Tooltip title='Refresh'>
            <IconButton sx={{pt: 0, height: '3rem'}} color='primary' onClick={handleRefresh}>
              <Refresh fontSize='large'/>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Paper sx={{
        height: '60vh',
        margin: '2%',
        ...Object.fromEntries(
          LAMBDAS.map(lambda => [[`& .${lambda.name}`], {color: lambda.color, fontWeight: 500}])
        ),
        '& .INFO': {color: 'lightskyblue'},
        '& .WARNING': {color: 'orange'},
        '& .ERROR': {color: 'tomato'}
      }}>
        <DataGridPro
          columns={columns}
          rows={getLogs.data || []}
          apiRef={apiRef.current ? apiRef : null}
          slots={{
            columnHeaderFilterIconButton: CustomFilterIcon,
            loadingOverlay: LinearProgress,
            toolbar: CustomToolbar
          }}
          loading={getLogs.isLoading}
          density='compact'
          getCellClassName={(params) => {
            if (params.field === 'app_name')
              return params.value;
            else if (params.field === 'severity')
              return params.value;
          }}
          disableColumnMenu
          onCellClick={handleCellClick}
        />
      </Paper>
      <Button
        sx={{width: '1rem', alignSelf: 'end', mr: '2%'}}
        size='large'
        onClick={handleClose}
      >
        Ok
      </Button>
      <Popover
        open={popoverOpen}
        anchorReference='anchorPosition'
        anchorPosition={popoverPosition}
        onClose={() => setPopoverOpen(false)}
      >
        <SyntaxHighlighter language={popoverType} style={atomOneLight} wrapLongLines>
          {popoverText}
        </SyntaxHighlighter>
      </Popover>
    </Dialog>
  );
};

export default DataFlowDetailsDialog;