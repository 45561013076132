import React, {useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {Box, IconButton, LinearProgress, Paper, Tooltip} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import Canvas from '../../navigation/canvas/Canvas';
import ErrorPopup from '../../common/ErrorPopup';
import ScrollableDialog from '../../common/ScrollableDialog';
import TableToolbar from '../../common/TableToolbar';
import ConsumerTypeService from '../../../apis/ConsumerTypeService';
import {columns} from './columns';

const ConsumerTypes = ({}) => {
  // ####################[ Setup and Initialization ]####################
  const queryClient = useQueryClient();

  const initializeColumns = (initialColumns) => {
    return initialColumns.concat({
      field: 'actions',
      headerName: 'Actions',
      description: 'Actions',
      minWidth: 175,
      renderCell: (params) => {
        return (
          <Box>
            <Tooltip title='View Schema'>
              <IconButton color='primary' onClick={(event) => handleDialogOpen(event, params.row)}>
                <InfoIcon/>
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    });
  };

  // ####################[ State Definitions ]####################
  const [cols, setCols] = useState(initializeColumns(columns));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [schema, setSchema] = useState([]);
  const [errDisplayText, setErrDisplayText] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);

  // ####################[ Query Definitions ]####################
  const getConsumerTypes = useQuery('getConsumerTypes', async () => {
      return await ConsumerTypeService.getAll();
    },
    {
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity,
      // cacheTime: (3 * minute)
    }
  );

  const handleError = (error) => {
    setErrorOpen(false);
    setErrDisplayText(`${error.message}: ${JSON.stringify(error.response.data)}`);
    setErrorOpen(true);

    console.error(`${error.message}: ${JSON.stringify(error.response.data)}`);
  };

  // ####################[ Event Handlers ]####################
  const handleDialogOpen = async (event, row) => {
    setSchema(row.schema)
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const refreshTable = async () => {
    console.info('Refreshing table');
    await queryClient.invalidateQueries('getConsumerTypes');
  };

  // ####################[ The Component ]####################
  return (
    <Box>
      <Canvas appName='nGEST' title='Consumer Types'>
        <TableToolbar onRefresh={refreshTable}/>
        <Paper sx={{height: '77vh', '& .disabled': {color: '#b2b29b'}}}>
          <DataGridPro
            columns={cols}
            rows={getConsumerTypes.data || []}
            getRowClassName={(params) => params.row.disabled ? 'disabled' : ''}
            slots={{loadingOverlay: LinearProgress, toolbar: GridToolbar}}
            loading={getConsumerTypes.isLoading}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  consumer_type_id: false,
                  consumer_type_description: false,
                  created_by: false,
                  created_datetime: false,
                  updated_by: false,
                  updated_datetime: false
                }
              }
            }}
            density='compact'
          />
        </Paper>
      </Canvas>
      <ScrollableDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        close={handleDialogClose}
        content={JSON.stringify(schema, undefined, 2)}
        scroll={'paper'}
      />
      <ErrorPopup
        open={errorOpen}
        setOpen={setErrorOpen}
        displayText={errDisplayText}
      />
    </Box>
  );
};

export default ConsumerTypes;