import { gridClasses } from '@mui/x-data-grid';
import { findParentElementFromClassName } from '@mui/x-data-grid/internals';
export function getFieldFromHeaderElem(colCellEl) {
  return colCellEl.getAttribute('data-field');
}
export function findHeaderElementFromField(elem, field) {
  return elem.querySelector(`[data-field="${field}"]`);
}
export function findGroupHeaderElementsFromField(elem, field) {
  var _elem$querySelectorAl;
  return Array.from((_elem$querySelectorAl = elem.querySelectorAll(`[data-fields*="|-${field}-|"]`)) != null ? _elem$querySelectorAl : []);
}
export function findGridCellElementsFromCol(col, api) {
  var _api$virtualScrollerR, _api$virtualScrollerR2;
  const root = findParentElementFromClassName(col, gridClasses.root);
  if (!root) {
    throw new Error('MUI: The root element is not found.');
  }
  const ariaColIndex = col.getAttribute('aria-colindex');
  if (!ariaColIndex) {
    return [];
  }
  const colIndex = Number(ariaColIndex) - 1;
  const cells = [];
  if (!((_api$virtualScrollerR = api.virtualScrollerRef) != null && _api$virtualScrollerR.current)) {
    return [];
  }
  const renderedRowElements = (_api$virtualScrollerR2 = api.virtualScrollerRef) == null ? void 0 : _api$virtualScrollerR2.current.querySelectorAll(`:scope > div > div > .${gridClasses.row}` // Use > to ignore rows from nested data grids (e.g. in detail panel)
  );
  renderedRowElements.forEach(rowElement => {
    const rowId = rowElement.getAttribute('data-id');
    if (!rowId) {
      return;
    }
    let columnIndex = colIndex;
    const cellColSpanInfo = api.unstable_getCellColSpanInfo(rowId, colIndex);
    if (cellColSpanInfo && cellColSpanInfo.spannedByColSpan) {
      columnIndex = cellColSpanInfo.leftVisibleCellIndex;
    }
    const cell = rowElement.querySelector(`[data-colindex="${columnIndex}"]`);
    if (cell) {
      cells.push(cell);
    }
  });
  return cells;
}
export function findGridHeader(api, field) {
  var _api$columnHeadersCon;
  const headers = (_api$columnHeadersCon = api.columnHeadersContainerElementRef) == null ? void 0 : _api$columnHeadersCon.current;
  if (!headers) {
    return null;
  }
  return headers.querySelector(`:scope > div > div > [data-field="${field}"][role="columnheader"]`);
}
export function findGridCells(api, field) {
  const container = api.virtualScrollerRef.current;
  const selectorFor = role => `:scope > div > div > div > [data-field="${field}"][role="${role}"]`;
  // TODO(v7): Keep only the selector for the correct role
  return Array.from(container.querySelectorAll(`${selectorFor('cell')}, ${selectorFor('gridcell')}`));
}